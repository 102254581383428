import React, { useState, useEffect } from 'react';



const GetNFTs = (props) => {


    const [image_detail, setImageDetail] = useState(0);

    useEffect(() => {

     //console.log(image_detail );
     //console.log(window.innerHeight)
     //console.log(window.innerWidth)
    });

    const paddingCalculate = (image_detail) => {
        console.log(((120*(1/image_detail))*(image_detail-1)))

        return ((120*(1/image_detail))*(image_detail-1)*1.25);
    }

    return (

        <section className="what-we-do-area  pt-50 pb-50 bg-color-1f1e2e">

            <div className="container ">
                <div className="row justify-content-start  pb-100">

                            <div className="col-12">
                        
                                    <div className="feature feature-1 service-1 text-center">
                                  
                                    <img src={props.S3_ADDRESS+"website/howtobuy.jpg"}  className="howtobyimg"  alt="image"  />
                              
                                    </div>
   
                            </div>

                   
                </div>
            </div>
            

        </section>
    )
}

export default GetNFTs;