import React, { useState, useEffect } from 'react';

import howTobuy_step_1_Image from 'how_to_buy/step_1.png'
import howTobuy_step_2_Image from 'how_to_buy/step_2.png'
import howTobuy_step_3_Image from 'how_to_buy/step_3.png'
import howTobuy_step_4_Image from 'how_to_buy/step_4.png'
import howTobuy_step_5_Image from 'how_to_buy/step_5.png'
import howTobuy_step_6_Image from 'how_to_buy/step_6.png'


const howTobuyStep = [
    (require('how_to_buy/step_1.png')),
    (require('how_to_buy/step_2.png')),
    (require('how_to_buy/step_3.png')),
    (require('how_to_buy/step_4.png')),
    (require('how_to_buy/step_5.png')),
    (require('how_to_buy/step_6.png'))
];

const imageDetail = [
    (require('how_to_buy/step_detail_1.png')),
    (require('how_to_buy/step_detail_2.png')),
    (require('how_to_buy/step_detail_3.png')),
    (require('how_to_buy/step_detail_4.png')),
    (require('how_to_buy/step_detail_5.png')),
    (require('how_to_buy/step_detail_6.png'))
];

const HowToBuy = (props) => {


    const [image_detail, setImageDetail] = useState(0);

    useEffect(() => {

     //console.log(image_detail );
     //console.log(window.innerHeight)
     //console.log(window.innerWidth)
    });

    const paddingCalculate = (image_detail) => {
        console.log(((120*(1/image_detail))*(image_detail-1)))

        return ((120*(1/image_detail))*(image_detail-1)*1.25);
    }

    return (

        <section className="what-we-do-area bg-darkgray pt-50 pb-50">

            <div className="container ">
                <div className="row justify-content-start pb-100">

                            <div className="col-9">
                        
                                    <div className="feature feature-1 service-1 text-center">
                                        <div className="how-to-buy-image-item">
                                            <img src={howTobuyStep[0]} alt="image"  onMouseEnter={() => setImageDetail(1)} onMouseLeave={() => setImageDetail(0)}/>
                                        </div>
                                    </div>
                                    <div className="feature feature-1 service-1 text-center">
                                        <div className="how-to-buy-image-item">
                                            <img src={howTobuyStep[1]} alt="image" onMouseEnter={() => setImageDetail(2)} onMouseLeave={() => setImageDetail(0)}/>
                                        </div>
                                    </div>
                                    <div className="feature feature-1 service-1 text-center">
                                        <div className="how-to-buy-image-item">
                                            <img src={howTobuyStep[2]} alt="image"  onMouseEnter={() => setImageDetail(3)} onMouseLeave={() => setImageDetail(0)}/>
                                        </div>
                                    </div>
                                    <div className="feature feature-1 service-1 text-center">
                                        <div className="how-to-buy-image-item">
                                            <img src={howTobuyStep[3]} alt="image"   onMouseEnter={() => setImageDetail(4)} onMouseLeave={() => setImageDetail(0)}/>
                                        </div>
                                    </div>
                                    <div className="feature feature-1 service-1 text-center">
                                        <div className="how-to-buy-image-item">
                                            <img src={howTobuyStep[4]} alt="image"   onMouseEnter={() => setImageDetail(5)} onMouseLeave={() => setImageDetail(0)}/>
                                        </div>
                                    </div>
                                    <div className="feature feature-1 service-1 text-center pb-100">
                                        <div className="how-to-buy-image-item">
                                            <img src={howTobuyStep[5]} alt="image"   onMouseEnter={() => setImageDetail(6)} onMouseLeave={() => setImageDetail(0)}/>
                                        </div>
                                    </div>
                              
                            </div>

                            <div className="col-3">
                        
                                    <div className="feature feature-1 service-1 text-center">
                    
                                    { image_detail > 0?
                                    
                                        <img src={imageDetail[image_detail-1]} alt="detail" style={{paddingTop:paddingCalculate(image_detail)+'px'}} />
                                    :

                                        <div/>
                                    }
                                    </div>
                              
                            </div>
                     
                </div>
            </div>
            

        </section>
    )
}

export default HowToBuy;