import React from 'react';
import PropTypes from 'prop-types';


{/* <div class="alert alert-primary" role="alert">
            Signed in successfully.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
</div> */}

class Alert extends React.Component {

  componentDidMount() {
    console.log("this.props.timeout => "+this.props.timeout)
    // this.timer = setTimeout(
    //   this.props.onClose,
    //   this.props.timeout
    // );
  }
  
  componentWillUnmount() {
    console.log("Alert clearTimeout unmount!! ")
    // clearTimeout(this.timer);
  }
  
  alertClass (type) {
    let classes = {
      error: 'alert-danger',
      alert: 'alert-warning',
      notice: 'alert-info',
      success: 'alert-primary'
    };
    return classes[type] || classes.success;
  }
  


  render() {
    const message = this.props.message;
    console.log(message)
    const alertClassName = 'alert alert-primary'+' animated fadeIn ';
 
    // return(
    //   <div className="container">
    //     <div className={ alertClassName } role="alert" onclick={ this.props.onClose } >
    //       { message.text }
    //       <button type="button" className="close" data-dismiss="alert" aria-label="Close"
    //         onClick={ this.pros.onClose }>
    //         <span aria-hidden="true">&times;</span>
    //       </button>
    //     </div>
    //    </div>
    // );

    return(
      <div className="container">
        <div className={ alertClassName } role="alert"  >
          { message.text }
          <button type="button" className="close" data-dismiss="alert" aria-label="Close" >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
       </div>
    );

  }
}

Alert.propTypes = {
  onClose: PropTypes.func,
  timeout: PropTypes.number,
  message: PropTypes.object.isRequired
};

Alert.defaultProps = {
  timeout: 300
};


export default Alert;