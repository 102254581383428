import React from 'react';
import PropTypes from "prop-types"


const MainBanner = (props) => {
    return (
        <div className="seo-agency-banner">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-5 offset-lg-2 col-md-12">
                        <div className="banner-wrapper-content">
                            {props.reloaded ?
                                <React.Fragment>
                                    <span className="sub-title">ART LANDMARK PROJECT RELOADED</span>
                                    <h1 className="animate__fadeInLeft" style={{ animationDuration: '0s' }} >Unique NFT Art collection created with Artificial Intelligence</h1>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <span className="sub-title">NEW ART LANDMARK PROJECT</span>
                                    <h1 className="animate__fadeInLeft" style={{ animationDuration: '0s' }} >Largest NFT Art Collection created with various A.I. techniques</h1>
                                </React.Fragment>
                            }


                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-start justify-content-md-center justify-content-sm-center justify-content-xs-center">

                                        <div className="btn-box animate__fadeInRight px-1" style={{ animationDuration: '0s' }}>
                                        {props.reloaded ?
                                            <a href="/artworksv2" className="default-btn" >
                                                <i className="flaticon-right"></i>
                                                Gallery
                                                <span></span>
                                            </a>
                                        :

                                            <a href="/sneakpeak" className="default-btn" >
                                            <i className="flaticon-right"></i>
                                            Gallery
                                            <span></span>
                                        </a>

                                        }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex justify-content-center">
                                        {props.reloaded ?
                                            <p className="animate__fadeInLeft" style={{ animationDuration: '0s' }} >Be part of art history and get your share of those entirely unique <span className="back-ground-text" >2,500</span> artworks created with Artificial Intelligence! This project brings together the two major current technologies: A.I. and Blockchain.</p>
                                            :
                                            <p className="animate__fadeInLeft" style={{ animationDuration: '0s' }} >Be part of art history and get your share of those entirely unique <span className="back-ground-text" >11,111</span> artworks created with Artificial Intelligence! This project brings together the two major current technologies: A.I. and Blockchain.</p>
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className="row justify-content-md-center justify-content-sm-center">
                                <div className="col-xl-5 col-lg-5 col-md-4 col-sm-7 col-xs-6 align-self-center circle-custom m-2 ">
                                    <div className="row justify-content-center ">
                                        <div className="col-8 col-sm-8 col-md-8 col-lg-12 col-xl-8">
                                            <div className="d-flex justify-content-center">
                                                <div className="custom-followus" style={{ marginBottom: "25px", marginTop: "25px" }}>
                                                    <h3 className=" animate__fadeInLeft" style={{ animationDuration: '0s' }} >DISCORD</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-center">
                                                <div className="btn-box-custom animate__fadeInLeft px-1" style={{ animationDuration: '0s', marginTop: "0px" }}>
                                                    <a href="https://discord.gg/Myn8Uw29Eh" className="default-btn btn-responsive" target="_blank">
                                                        <span></span>
                                                        <i className='bx bxl-discord bx-custom'></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-xl-5 col-lg-6 col-md-4 col-sm-7 col-xs-6 align-self-center circle-custom m-2 ">
                                    <div className="row justify-content-md-center ">
                                        <div className="col-10 col-sm-10  col-md-12 ">
                                            <div className="d-flex justify-content-center">

                                                <div className="custom-followus" style={{ marginBottom: "25px", marginTop: "25px" }}>
                                                    <h3 className=" animate__fadeInLeft" style={{ animationDuration: '0s' }} >FOLLOW US</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-center">
                                                <div className="btn-box-custom animate__fadeInRight px-1" style={{ animationDuration: '0s', marginTop: "0px" }}>
                                                    <a href="https://twitter.com/@niftycashart" className="default-btn btn-responsive" target="_blank">
                                                        <span></span>
                                                        <i className='bx bxl-twitter bx-custom'></i>
                                                    </a>
                                                </div>
                                                <div className="btn-box-custom animate__fadeInRight px-1" style={{ animationDuration: '0s', marginTop: "0px" }}>
                                                    <a href="https://www.instagram.com/nifty_riots/" className="default-btn btn-responsive" target="_blank" >
                                                        <span></span>
                                                        <i className='bx bxl-instagram-alt bx-custom  bx-responsive'></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">

                        <div className="banner-wrapper-image" >
                            {props.reloaded ?
                                <img src={props.S3_ADDRESS + "webimages/Hasher.ai-reveal-small.png"} alt="image" className="animate__fadeInDown" style={{ animationDuration: '0s', width: '75%', height: '75%' }} />
                                :
                                <img src={props.S3_ADDRESS + "webimages/histo_sel00000.png"} alt="image" className="animate__fadeInDown" style={{ animationDuration: '0s', width: '75%', height: '75%' }} />
                            }
                        </div>



                        {/* <video className="video-fluid z-depth-1 center-block animate__fadeIn" style={{animationDuration: '5s'}} autoPlay loop controls muted >
                            <source src="https://mdbootstrap.com/img/video/Sail-Away.mp4" type="video/mp4" />
                        </video> */}


                        {/* <video className="video-fluid z-depth-1 center-block animate__fadeIn" style={{animationDuration: '5s'}} autoPlay loop controls muted >
                            <source src={props.S3_ADDRESS+"webimages/latent_so1.mp4"} type="video/mp4" />
                        </video>*/}


                    </div>
                </div>
            </div>
        </div>
    )
}

MainBanner.propTypes = {
    S3_ADDRESS: PropTypes.string
};

export default MainBanner;
