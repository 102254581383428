import React, { useState } from 'react';
import ReactDOM from "react-dom";
import PropTypes from "prop-types"
import CountDown from './CountDown';
import '../../stylesheets/custom'
import '../../stylesheets/custom.css'

// import Percent20 from "./Percent20"
// import Percent40 from "./Percent40"
// import Percent60 from "./Percent60"
//import Percent80 from "./Percent80"
// import Percent100 from "./Percent100"

import Percent25 from "./Percent25"
import Percent50 from "./Percent50"
import Percent75 from "./Percent75"
import Percent100 from "./Percent100_2"

import testMP4 from './../../../videos/test.mp4'


const Denominations = (props) => {

    const [onilne, setOnline] = useState(false);


    return (
        <React.Fragment>
            <section className="projects-area bg-color-black pt-5 pb-5 ">
                <div className="container bg-color-black border-line-bottom pb-5">
                    <div className="row justify-content-center mt-1 ">

                        <React.Fragment>
                            <div className="col-12 col-md-12 col-lg-12 pb-5 ">
                                <div className="d-flex flex-column justify-content-center" >
                                    <h2 className="rockwell-font" style={{ color: '#ffffff', fontSize: '52px' }}>
                                    The
                                            <span style={{ color: '#F2356D' }}> Denominations</span>
                                    </h2>

                                </div>

                            </div>
                            <div className="col-12 col-md-12 col-lg-12   justify-content-center align-content-center">
                                <div className="d-flex flex-row justify-content-center" >
                                    <div className="d-flex flex-column text-center justify-content-center">
                                        <img src={props.S3_ADDRESS + "website/deno-0.2.jpg"} className="imgPoster" alt="image" />
                                        <p> 0.2 <span style={{ color: '#F2356D' }}>ETH</span></p>
                                    </div>
                                    <div className="d-flex flex-column text-center justify-content-center">
                                        <img src={props.S3_ADDRESS + "website/deno-0.5.jpg"} className="imgPoster" alt="image" />
                                        <p>0.5 <span style={{ color: '#F2356D' }}>ETH</span></p>
                                    </div>
                                    <div className="d-flex flex-column text-center justify-content-center">
                                        <img src={props.S3_ADDRESS + "website/deno-1.jpg"} className="imgPoster" alt="image" />
                                        <p>1 <span style={{ color: '#F2356D' }}>ETH</span></p>
                                    </div>
                                    <div className="d-flex flex-column text-center justify-content-center">
                                        <img src={props.S3_ADDRESS + "website/deno-2.jpg"} className="imgPoster" alt="image" />
                                        <p>2 <span style={{ color: '#F2356D' }}>ETH</span></p>
                                    </div>
                                    <div className="d-flex flex-column text-center justify-content-center">
                                        <img src={props.S3_ADDRESS + "website/deno-5.jpg"} className="imgPoster" alt="image" />
                                        <p>5 <span style={{ color: '#F2356D' }}>ETH</span></p>
                                    </div>
                                    <div className="d-flex flex-column text-center justify-content-center">
                                        <img src={props.S3_ADDRESS + "website/deno-10.jpg"} className="imgPoster" alt="image" />
                                        <p>10 <span style={{ color: '#F2356D' }}>ETH</span></p>
                                    </div>
                                    <div className="d-flex flex-column text-center justify-content-center">
                                        <img src={props.S3_ADDRESS + "website/deno-50.jpg"} className="imgPoster" alt="image" />
                                        <p>50 <span style={{ color: '#F2356D' }}>ETH</span></p>
                                    </div>

                                </div>
                            </div>



                        </React.Fragment>

                    </div>

                    <div className="row justify-content-center mt-0 pt-5">

                    <div className="col-12 col-md-12 col-lg-12 pt-4 ">
                                <div className="d-flex justify-content-center align-items-center">
                                    <p className="rockwell-font" style={{ color: '#ffffff', fontSize: '26px', fontWeight: '500' }} > Want Some? Discord is LIVE now</p>
                                    <a href="https://discord.gg/Myn8Uw29Eh" className="d-block" style={{ width: "80px" }} target="_blank">
                                            <i className='d-flex justify-content-center bx bxl-discord bx-normal-custom'></i>

                                    </a>
                                </div>

                            </div>
                    </div>

                </div>
            </section>

        </React.Fragment>
    )
}

Denominations.propTypes = {
    artWorksData: PropTypes.array,
    S3_ADDRESS: PropTypes.string
};

export default Denominations;
