import React from "react"
import PropTypes from "prop-types"
import { RecoilRoot } from 'recoil'
import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
import Web3 from 'web3';
import mutantmoneysociety from '../contracts/Mutantmoneysociety.json'

import CheckMark from '../../../assets/images/check-small.png';
import AlertMark from '../../../assets/images/alert-small.png';

import axios from 'axios';

//<a className="btn btn-block default-btn-custom "  rel="nofollow" data-method="delete" style={{"height":"30px","paddingTop":"5px"}} href={"/whitelists/" + token["id"]}>Destroy</a>


class AuthorizeWL extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPrice: 0.1,
      data: [],
      sum: 0,
      sumentries: 0,
      sumwhitelistedentries: 0,
      sumwhitelistedentriesaccepted: 0

    };
    //console.log("**********  authenticity_token *************")
    //console.log(props.authenticity_token)
    //this.onRefresh = this.onRefresh.bind(this);

  }



  wecheck(myVar) {
    if (myVar) {
      return <img src={CheckMark} alt='CheckMark' />
    }
    else {
      return <img src={AlertMark} alt='Error' />
    }
  }

  //mounting the main Element
  async componentDidMount() {
    //await this.loadWeb3(this.props.dispatch)

    const res = await axios.get("/customindex", {
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type'
      }
    });
    //console.log(res)
    let data = res.data;

    //console.log("data", data)

    this.setState({ data: data })
    this.setState({ sumentries : data.length })
    let tmpSWL = 0
    data.map( (item)=>{ item.addedtocontract? ++tmpSWL : tmpSWL  })
    this.setState({ sumwhitelistedentries : tmpSWL })

    // let tmpsum = 0
    // this.state.data.map((token, indexo) => { tmpsum = tmpsum + parseInt(token["amount"]) })
    // this.setState({ sum: tmpsum })

    // let tmpsumentries = 0
    // this.state.data.map((token, indexo) => { tmpsumentries = tmpsumentries + 1 })
    // this.setState({ sumentries: tmpsumentries })

    // console.log(data);
    // let tmpsumwhitelistedentries = 0
    // this.state.data.map((token, indexo) => {
    //   console.log("pisut")
    //   console.log(token)
    //   console.log(token["whitelisted"].toString())
    //   token["whitelisted"].toString() ==="true" ?   tmpsumwhitelistedentries = tmpsumwhitelistedentries + 1 : 11 })
    // this.setState({ sumwhitelistedentries: tmpsumwhitelistedentries })

    // console.log(data);
    // let tmpsumwhitelistedentriesaccepted = 0
    // this.state.data.map((token, indexo) => { token["whitelisted"].toString() ==="true" ?   tmpsumwhitelistedentriesaccepted = tmpsumwhitelistedentriesaccepted + parseInt(token["amount"]) : 11 })
    // this.setState({ sumwhitelistedentriesaccepted: tmpsumwhitelistedentriesaccepted })



  }


  render() {

    return (
      <React.Fragment>
        <RecoilRoot>
          <NavbarTwoCustom />

          <section className="what-we-do-area bg-fafafb pt-100 pb-100">

            <table className="table table-striped table-hover " style={{ tableLayout: "fixed", wordWrap: "break-word" }}>
              <thead className="thead-dark">
                <tr>
                  <th scope="col" className="text-left" style={{ width: "20%" }}>ETH Address</th>
                  <th scope="col" className="text-center" style={{ width: "10" }}>Handle</th>
                  <th scope="col" className="text-center" style={{ width: "10%" }} >email | sent</th>
                  <th scope="col" className="text-center" style={{ width: "20%" }}>comment</th>
                  <th scope="col" className="text-center" style={{ width: "5%" }}>Added Invites</th>
                  <th scope="col" className="text-center" style={{ width: "5%" }}>Added 2 Smart C.</th>
                  <th scope="col" className="text-center" style={{ width: "5%" }}>Manually OKed Value</th>
                  <th scope="col" className="text-center" style={{ width: "10%" }}>Action</th>
                  <th scope="col" style={{ width: "10%" }}></th>
                </tr>
              </thead>
              <tbody className="tbody-dark" align="center">
                {this.state.data.map((token, indexo) =>

                  <tr key={indexo}>

                    <td>{token["address"]}</td>
                    <td>{token["handle"]}</td>
                    <td>{token["email"]} | {this.wecheck(token["emailsent"])}</td>
                    <td>{token["comment"]}</td>
                    <td>{this.wecheck(token["withaddedvalue"])}</td>
                    <td>{this.wecheck(token["addedtocontract"])}</td>
                    <td>{this.wecheck(token["manuallyvalidated"])}</td>
                    <td>
                      <div className="d-flex flex-column">
                        {
                          token["manuallyvalidated"] == true ?
                            <React.Fragment>
                              <form id={token["ethaddress"] + "-" + token["id"] + "-false"} action={"/whitelists/" + token["id"]} method="post" onSubmit={(e) => {
                                console.log("Submit")
                              }}>

                                <input
                                  type='hidden'
                                  name="authenticity_token"
                                  value={this.props.authenticity_token}
                                  ref={(input) => { this.authenticity_token = input }}

                                />
                                <input
                                  type='hidden'
                                  name="_method"
                                  value="patch"
                                  ref={(input) => { this._method = input }}
                                />
                                <input
                                  type='hidden'
                                  name="whitelist[manuallyvalidated]"
                                  value="false"
                                />
                                <input
                                  type='hidden'
                                  name="whitelist[redirect]"
                                  value="true"
                                />
                              </form>
                              <button form={token["ethaddress"] + "-" + token["id"] + "-false"} style={{ "height": "30px", "paddingTop": "5px" }} className="btn btn-block default-btn-custom p-1 m-1" type="submit">REJECT</button>


                            </React.Fragment>
                            :
                            token["manuallyvalidated"] == false ?

                              <React.Fragment>
                                <form id={token["ethaddress"] + "-" + token["id"] + "-true"} action={"/whitelists/" + token["id"]} method="post" onSubmit={(e) => {
                                  console.log("Submit")
                                }}>

                                  <input
                                    type='hidden'
                                    name="authenticity_token"
                                    value={this.props.authenticity_token}
                                    ref={(input) => { this.authenticity_token = input }}

                                  />
                                  <input
                                    type='hidden'
                                    name="_method"
                                    value="patch"
                                    ref={(input) => { this._method = input }}
                                  />
                                  <input
                                    type='hidden'
                                    name="whitelist[manuallyvalidated]"
                                    value="true"
                                  />
                                  <input
                                    type='hidden'
                                    name="whitelist[redirect]"
                                    value="true"
                                  />
                                </form>
                                <button form={token["ethaddress"] + "-" + token["id"] + "-true"} style={{ "height": "30px" }} className="btn btn-block default-btn-custom p-1 m-1" type="submit">SET OK</button>

                              </React.Fragment>
                              :
                              <React.Fragment>
                                <form id={token["ethaddress"] + "-" + token["id"] + "-true"} action={"/whitelists/" + token["id"]} method="post" onSubmit={(e) => {
                                  console.log("Submit")
                                }}>

                                  <input
                                    type='hidden'
                                    name="authenticity_token"
                                    value={this.props.authenticity_token}
                                    ref={(input) => { this.authenticity_token = input }}

                                  />
                                  <input
                                    type='hidden'
                                    name="_method"
                                    value="patch"
                                    ref={(input) => { this._method = input }}
                                  />
                                  <input
                                    type='hidden'
                                    name="whitelist[manuallyvalidated]"
                                    value="true"
                                  />
                                  <input
                                    type='hidden'
                                    name="whitelist[redirect]"
                                    value="true"
                                  />
                                </form>
                                <button form={token["ethaddress"] + "-" + token["id"] + "-true"} style={{ "height": "30px" }} className="btn btn-block default-btn-custom p-1 m-1" type="submit">SET OK</button>

                                <form id={token["ethaddress"] + "-" + token["id"] + "-false"} action={"/whitelists/" + token["id"]} method="post" onSubmit={(e) => {
                                  console.log("Submit")
                                }}>

                                  <input
                                    type='hidden'
                                    name="authenticity_token"
                                    value={this.props.authenticity_token}
                                    ref={(input) => { this.authenticity_token = input }}

                                  />
                                  <input
                                    type='hidden'
                                    name="_method"
                                    value="patch"
                                    ref={(input) => { this._method = input }}
                                  />
                                  <input
                                    type='hidden'
                                    name="whitelist[manuallyvalidated]"
                                    value="false"
                                  />
                                  <input
                                    type='hidden'
                                    name="whitelist[redirect]"
                                    value="true"
                                  />
                                </form>
                                <button form={token["ethaddress"] + "-" + token["id"] + "-false"} style={{ "height": "30px", "paddingTop": "5px" }} className="btn btn-block default-btn-custom p-1 m-1" type="submit">REJECT</button>

                              </React.Fragment>
                        }

                      </div>

                    </td>
                     <td>
                        <div className="d-flex flex-column">
                          <a className="btn btn-block default-btn-custom p-1 m-1"  rel="nofollow" data-method="delete" style={{"height":"30px","paddingTop":"5px"}} href={"/whitelists/" + token["id"]+"?whitelist[redirect]=true"}>Destroy</a>
                        </div>
                      </td>
                  </tr>
                )}

              </tbody>

              <tfoot className="tfoot-dark" >
                     <tr>
                       <th scope="col">NB Whitelisted Entries (how many wallets Whitelisted) </th>
                       <th className="text-center">{this.state.sumwhitelistedentries}</th>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                     </tr>
                   </tfoot>


                   <tfoot className="tfoot-dark" >
                     <tr>
                       <th scope="col">NB Entries (How many wallets in total) </th>
                       <th className="text-center">{this.state.sumentries}</th>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                       <td></td>
                     </tr>
                   </tfoot>

                
            </table>

          </section>

          <GoTop />
        </RecoilRoot>
      </React.Fragment>)
  }
}

AuthorizeWL.propTypes = {
  artWorksData: PropTypes.array,
  S3_ADDRESS: PropTypes.string
};

export default AuthorizeWL
