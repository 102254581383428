import React from "react"
import PropTypes from "prop-types"

class ArtWorkShow extends React.Component {
  render () {
    return (
      <React.Fragment>
          <section className="overview-area ptb-100 pt-0">
            <div className="container">
                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={this.props.S3_ADDRESS+"artworks/"+this.props.data["internalname"] } alt="image" />
                        </div>
                    </div>
                    
                    <div className="overview-content" style={{display: "None"}}>
                        <div className="content right-content">
                            <span className="sub-title">Information</span>
                            <p id="notice">{this.props.notice}</p>
                            <p>
                              <strong>Name: </strong>
                              {this.props.data["name"]}
                            </p>
                            <p>
                              <strong>Description: </strong>
                              {this.props.data["description"]}
                            </p>
                            <p>
                              <strong>Internal name: </strong>
                              {this.props.data["internalname"]}
                            </p>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}
            </div>
        </section>
      </React.Fragment>
    );
  }
}

ArtWorkShow.propTypes = {
  data:  PropTypes.object.isRequired,
  S3_ADDRESS:  PropTypes.string
};
export default ArtWorkShow
