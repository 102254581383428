import React from 'react';

const Percent80 = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={props.width} height={props.height} viewBox="0 0 981.000000 982.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,982.000000) scale(0.100000,-0.100000)"
        fill={props.color} stroke="none">
        <path d="M4450 9799 c-570 -55 -1180 -227 -1695 -479 -479 -235 -880 -520
-1277 -909 -772 -755 -1235 -1656 -1423 -2768 -59 -348 -61 -1094 -5 -1438
170 -1041 602 -1936 1281 -2650 483 -508 990 -869 1599 -1138 477 -210 1095
-368 1563 -398 231 -15 873 -6 1021 15 1113 156 2099 639 2861 1401 737 738
1208 1666 1390 2742 43 253 58 751 35 1144 -30 523 -220 1212 -480 1744 -249
507 -533 901 -954 1320 -500 498 -1026 844 -1656 1090 -360 140 -804 256
-1145 299 -252 32 -893 46 -1115 25z m850 -619 c427 -34 853 -139 1273 -314
884 -367 1636 -1049 2112 -1915 255 -463 450 -1088 496 -1586 15 -167 15 -731
0 -900 -37 -404 -161 -868 -346 -1290 -399 -916 -1157 -1698 -2070 -2135 -444
-213 -960 -360 -1408 -402 -174 -16 -746 -15 -912 1 -537 52 -1175 261 -1665
546 -362 210 -654 438 -955 746 -522 534 -883 1179 -1075 1924 -82 317 -106
476 -120 804 -12 272 0 665 26 840 130 879 488 1638 1083 2296 713 788 1679
1275 2771 1395 100 11 611 4 790 -10z"/>
        <path d="M2675 6429 c-271 -16 -472 -188 -547 -467 -32 -119 -37 -412 -10
-545 36 -174 105 -289 231 -390 42 -33 77 -63 79 -67 1 -4 -23 -24 -55 -45
-98 -65 -143 -107 -193 -183 -94 -139 -126 -299 -117 -577 12 -334 54 -462
199 -604 149 -146 295 -187 637 -179 172 5 191 7 256 32 166 64 306 181 368
308 55 112 67 189 74 463 7 293 0 345 -62 473 -53 110 -123 190 -234 265 l-75
50 24 16 c166 108 279 274 299 441 23 178 8 518 -26 619 -23 68 -96 173 -161
231 -72 63 -190 125 -273 144 -75 17 -271 24 -414 15z m266 -339 c44 -13 76
-45 109 -111 44 -86 53 -160 48 -422 -3 -215 -5 -236 -26 -282 -51 -112 -137
-161 -266 -153 -190 12 -228 93 -228 488 0 360 30 445 172 491 28 9 143 3 191
-11z m30 -1328 c58 -26 114 -86 134 -142 32 -91 46 -444 25 -627 -14 -124 -67
-215 -148 -255 -39 -19 -63 -23 -142 -23 -148 0 -215 36 -272 145 -23 44 -23
49 -23 365 1 360 7 414 57 471 33 38 69 59 133 81 55 18 181 10 236 -15z"/>
        <path d="M4545 6429 c-49 -4 -110 -14 -135 -23 -253 -94 -420 -331 -449 -638
-16 -163 -22 -895 -12 -1353 10 -481 13 -501 101 -682 77 -157 188 -259 362
-331 59 -25 75 -27 250 -30 152 -3 197 -1 245 13 69 20 164 70 239 127 126 95
240 321 265 527 15 127 23 1314 10 1566 -12 241 -30 332 -91 461 -89 192 -246
321 -426 353 -78 14 -247 18 -359 10z m236 -350 c47 -12 114 -61 133 -98 38
-73 41 -148 41 -1081 0 -1060 2 -1026 -79 -1106 -41 -40 -64 -54 -106 -64
-115 -28 -267 17 -302 90 -40 81 -43 159 -43 1120 0 866 1 928 18 977 18 52
82 134 121 155 45 25 134 28 217 7z"/>
        <path d="M6126 6394 c-169 -52 -272 -211 -290 -449 -8 -105 10 -455 27 -526
24 -100 101 -208 181 -254 106 -60 306 -60 427 1 93 47 177 178 204 319 18 91
20 417 5 545 -12 98 -61 214 -113 268 -38 40 -112 80 -182 98 -65 18 -197 16
-259 -2z m218 -225 c14 -11 33 -34 42 -51 23 -44 37 -263 31 -471 -5 -160 -8
-184 -29 -225 -43 -87 -154 -117 -213 -58 -56 56 -86 299 -68 539 14 169 33
231 82 264 45 30 118 31 155 2z"/>
        <path d="M7087 6373 c-3 -5 -66 -240 -142 -523 -75 -283 -213 -800 -307 -1149
-100 -374 -166 -637 -160 -643 5 -5 60 -7 122 -6 l114 3 228 860 c353 1331
385 1455 380 1460 -8 8 -230 6 -235 -2z"/>
        <path d="M7353 5299 c-121 -47 -221 -185 -244 -339 -14 -89 -6 -488 12 -595
21 -132 105 -248 217 -301 83 -39 220 -46 323 -14 65 19 80 29 139 88 79 81
124 168 141 271 7 47 9 167 7 330 -5 287 -9 307 -87 429 -73 112 -164 152
-345 151 -86 0 -123 -5 -163 -20z m236 -202 c33 -16 60 -55 72 -103 16 -62 23
-416 10 -548 -9 -102 -14 -123 -36 -152 -70 -92 -209 -56 -245 64 -7 20 -14
141 -17 267 -8 337 12 430 101 475 42 21 67 20 115 -3z"/>
      </g>
    </svg>

  );
}
export default Percent80;