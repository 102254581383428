import React from 'react';

const GenesisCollection = () => {
    return (
        <section className="what-we-do-area bg-black pt-100 pb-150">

            <div className="container ">
                <div className="row justify-content-center">


                        <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">

                            <div className="feature feature-1 service-1 text-center">

                                <h4></h4><hr />

                                <p align="left">

                                            1


                                            <br></br>Join our Discord for any questions.
                                </p>

                                <hr />
                            </div>

                        </div>

                </div>
            </div>


        </section>
    )
}

export default GenesisCollection;
