import React, { useState, useEffect } from 'react';



const howToAddContractStep = [
    (require('contract/token_1.png')),
    (require('contract/token_2.png')),
    (require('contract/token_3.png')),
    (require('contract/token_4.png')),
    (require('contract/token_5.png')),
    (require('contract/token_6.png'))
];

const imageDetail = [
    (require('contract/metamask_access.png')),
    (require('contract/NRIOTS_add_token.png')),
    (require('contract/NRIOTS_add_token.png')),
    (require('contract/NRIOTS_add_symbol.png')),
    (require('contract/NRIOTS_token.png')),
    (require('contract/NRIOTS_finished.png'))

];

const HowToAddContract = (props) => {


    const [image_detail, setImageDetail] = useState(0);

    useEffect(() => {

  

    });

    return (
        <section className="what-we-do-area bg-darkgray pt-50 pb-50">

            <div className="container ">
                <div className="row justify-content-start pb-100">

                            <div className="col-9 pr-0">
                        
                                    <div className="feature feature-1 service-1 text-center">
                                        <div className="how-to-buy-image-item">
                                            <img src={howToAddContractStep[0]} alt="image"  onMouseEnter={() => setImageDetail(1)} onMouseLeave={() => setImageDetail(0)}/>
                                        </div>
                                    </div>
                                    <div className="feature feature-1 service-1 text-center">
                                        <div className="how-to-buy-image-item">
                                            <img src={howToAddContractStep[1]} alt="image" onMouseEnter={() => setImageDetail(2)} onMouseLeave={() => setImageDetail(2)}/>
                                        </div>
                                    </div>
                                    {
                                        image_detail == 2?

                                        <div className="feature feature-1 service-1 text-center">
                                        <div className="d-flex justify-content-right">
                                            <a href="https://etherscan.io/address/0x99B453D39Dc4cFDB438C721B9516ccf0f38CfEC5" target="_blank" >
                                                <div className="trapizium" style={{ color: '#F2356D' }}>Contract address :  <b>0xABCABCABCABCABACBACBABCBCABCABACBACBABCB</b> </div>
                                            </a>
                                        </div>
                                        </div>
                                        :
                                        <div/>
                                                                            
                                    }
                                    <div className="feature feature-1 service-1 text-center">
                                        <div className="how-to-buy-image-item">
                                            <img src={howToAddContractStep[2]} alt="image"  onMouseEnter={() => setImageDetail(3)} onMouseLeave={() => setImageDetail(0)}/>
                                        </div>
                                    </div>
                                    <div className="feature feature-1 service-1 text-center">
                                        <div className="how-to-buy-image-item">
                                            <img src={howToAddContractStep[3]} alt="image"   onMouseEnter={() => setImageDetail(4)} onMouseLeave={() => setImageDetail(0)}/>
                                        </div>
                                    </div>
                                    <div className="feature feature-1 service-1 text-center">
                                        <div className="how-to-buy-image-item">
                                            <img src={howToAddContractStep[4]} alt="image"   onMouseEnter={() => setImageDetail(5)} onMouseLeave={() => setImageDetail(0)}/>
                                        </div>
                                    </div>
                                    <div className="feature feature-1 service-1 text-center pb-100">
                                        <div className="how-to-buy-image-item">
                                            <img src={howToAddContractStep[5]} alt="image"   onMouseEnter={() => setImageDetail(6)} onMouseLeave={() => setImageDetail(0)}/>
                                        </div>
                                    </div>
                              
                            </div>

                            <div className="col-3 pl-0">
                        
                                    <div className="feature feature-1 service-1 text-center">
                    
                                    { image_detail > 0?
                                        image_detail != 2?
                                        <img src={imageDetail[image_detail-1]} alt="image" style={{paddingTop:50*(image_detail-1)+'px'}} />
                                        :


                                        <div/>

                                    :

                                        <div/>
                                    }
                                    </div>
                              
                            </div>
                     
                </div>
            </div>
            

        </section>
    )
}

export default HowToAddContract;