import React from 'react';
import Web3 from 'web3';


const Transaction = (mprops) => {

/*
    constructor(props) {
       super(props);
       this.state = {
           isToggleOn: true
       };

       // This binding is necessary to make `this` work in the callback    this.handleClick = this.handleClick.bind(this);
     }
*/


    return (
        <section className="what-we-do-area bg-black pt-100 pb-70">

            <div className="container ">
                    <div className="row">
                        <div className="col-sm-12">

                            <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">

                                    <div className="feature feature-1 service-1 text-center">

                                        <h4>Current Price :{ mprops.currentPrice/(10**18)  } ETH</h4><hr/>

                                        <p align="left">


/*
                                        <form onSubmit={(e) => {
                                            e.preventDefault()    //doesnt let the page refresh
                                        //    const tknumber = this.msize.value
                                        //    const totalPrice = tknumber *  props.currentPrice;
                                        //    console.log('totalPrice: ', totalPrice)
                                        //    this.wemint(tknumber,totalPrice.toString())

                                            }}>

                                            <input
                                              type='text'
                                              className="form-control mb1"
                                              placeholder='e.g. 1-20'
                                              ref={(input) => { 1}}
                                              required />

                                            <input type='submit' className='btn btn-block btn-primary' value ='PURCHASE' />

                                            </form>

*/

                                        </p>


                                        <p align="left">Discord: <a className="color-F2356D" href="https://discord.gg/Myn8Uw29Eh"><b>MUTANTMONEYSOCIETY</b></a>
                                        </p>



                                        <hr/>
                                        </div>

                            </div>

                </div>
                </div>
            </div>


        </section>
    )
}

export default Transaction;
