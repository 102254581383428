import React from 'react';

const Percent100 = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={props.width} height={props.height} viewBox="0 0 981.000000 982.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,982.000000) scale(0.100000,-0.100000)"
        fill={props.color} stroke="none">
        <path d="M4450 9799 c-570 -55 -1180 -227 -1695 -479 -479 -235 -880 -520
-1277 -909 -772 -755 -1235 -1656 -1423 -2768 -59 -348 -61 -1094 -5 -1438
170 -1041 602 -1936 1281 -2650 483 -508 990 -869 1599 -1138 477 -210 1095
-368 1563 -398 231 -15 873 -6 1021 15 1113 156 2099 639 2861 1401 737 738
1208 1666 1390 2742 43 253 58 751 35 1144 -30 523 -220 1212 -480 1744 -249
507 -533 901 -954 1320 -500 498 -1026 844 -1656 1090 -360 140 -804 256
-1145 299 -252 32 -893 46 -1115 25z m850 -619 c427 -34 853 -139 1273 -314
884 -367 1636 -1049 2112 -1915 255 -463 450 -1088 496 -1586 15 -167 15 -731
0 -900 -37 -404 -161 -868 -346 -1290 -399 -916 -1157 -1698 -2070 -2135 -444
-213 -960 -360 -1408 -402 -174 -16 -746 -15 -912 1 -537 52 -1175 261 -1665
546 -362 210 -654 438 -955 746 -522 534 -883 1179 -1075 1924 -82 317 -106
476 -120 804 -12 272 0 665 26 840 130 879 488 1638 1083 2296 713 788 1679
1275 2771 1395 100 11 611 4 790 -10z"/>
        <path d="M5228 6390 c-242 -44 -410 -191 -483 -422 -38 -120 -48 -317 -48
-963 0 -645 10 -843 48 -963 54 -170 164 -300 320 -378 63 -31 84 -36 185 -44
279 -20 412 17 559 156 81 76 132 160 170 279 54 169 56 192 56 940 0 724 -3
786 -46 945 -27 102 -93 223 -154 285 -60 60 -162 116 -265 145 -84 24 -265
34 -342 20z m257 -336 c19 -9 46 -32 61 -51 60 -79 58 -48 62 -899 5 -974 1
-1024 -77 -1107 -44 -49 -79 -61 -166 -62 -70 0 -86 4 -127 28 -30 17 -57 44
-74 72 -23 41 -27 61 -36 198 -11 182 -1 1452 13 1611 11 125 27 167 74 196
67 41 199 48 270 14z"/>
        <path d="M3465 6371 c-92 -28 -114 -38 -181 -86 -135 -97 -229 -269 -254 -467
-17 -128 -24 -1143 -11 -1423 13 -274 21 -320 87 -454 106 -216 305 -330 575
-331 315 0 551 185 629 494 37 145 43 318 37 1061 l-5 700 -44 106 c-57 136
-90 191 -151 256 -61 65 -123 105 -207 134 -55 19 -91 23 -240 25 -148 3 -184
1 -235 -15z m347 -327 c23 -16 54 -53 71 -82 29 -53 30 -58 39 -255 5 -111 7
-508 5 -882 -5 -742 -5 -746 -63 -815 -35 -41 -70 -58 -151 -71 -90 -15 -137
-2 -196 54 -32 31 -47 56 -56 92 -29 112 -39 850 -22 1515 7 279 10 308 29
345 26 51 66 88 122 115 66 31 166 23 222 -16z"/>
        <path d="M1958 6158 c-305 -319 -398 -421 -398 -438 0 -24 159 -202 173 -193
7 4 59 48 117 98 58 50 131 113 163 139 l57 48 0 -931 0 -931 -160 0 -160 0 0
-165 0 -165 527 0 526 0 -7 157 c-3 86 -8 158 -9 159 -1 2 -72 7 -157 11
l-155 8 -3 1208 -2 1207 -154 0 -154 0 -204 -212z"/>
        <path d="M6694 6356 c-214 -49 -296 -208 -297 -576 0 -240 36 -378 124 -474
74 -81 126 -101 269 -101 100 0 122 3 168 24 66 30 124 94 169 187 45 95 53
153 53 397 0 184 -2 215 -21 270 -46 139 -124 230 -221 260 -77 24 -173 29
-244 13z m173 -206 c43 -39 48 -78 48 -375 0 -306 -5 -335 -59 -371 -41 -26
-110 -14 -150 25 l-31 31 -3 298 c-4 332 0 361 51 392 43 27 116 27 144 0z"/>
        <path d="M7560 6338 c-32 -17 -35 -24 -180 -569 -306 -1147 -403 -1531 -389
-1540 22 -14 173 -11 192 4 16 12 134 444 412 1507 69 267 133 507 140 535 8
27 10 54 6 58 -15 15 -154 19 -181 5z"/>
        <path d="M7849 5380 c-142 -24 -233 -115 -286 -285 -15 -50 -18 -93 -18 -265
1 -300 24 -407 109 -506 72 -85 207 -127 338 -105 172 28 279 143 314 339 18
102 18 393 -1 495 -16 94 -60 190 -106 235 -45 44 -121 79 -200 91 -71 12 -84
12 -150 1z m153 -210 c53 -41 62 -85 66 -322 5 -298 -12 -398 -73 -426 -41
-19 -109 -15 -138 8 -47 37 -52 72 -52 380 0 259 2 288 18 313 41 63 130 86
179 47z"/>
      </g>
    </svg>
  );
}
export default Percent100;