import React from "react"
import PropTypes from "prop-types"

import { ToastProvider } from 'react-toast-notifications';
import PreSaleNewPage from './PreSaleNewPage'


class PreSaleNewPageWrap extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (

            <ToastProvider autoDismiss autoDismissTimeout={10000}>
                <PreSaleNewPage
                    S3_ADDRESS={this.props.S3_ADDRESS}
                    presaleopen={this.props.presaleopen}
                    presale_limit={this.props.presale_limit}
                    authenticity_token={this.props.authenticity_token}
                    user_signed_in={this.props.user_signed_in} />
            </ToastProvider>
        )
    }
}

PreSaleNewPageWrap.propTypes = {
    S3_ADDRESS: PropTypes.string
};

export default PreSaleNewPageWrap