import React from 'react';
import Web3 from 'web3';




const TransactionLoading = (props) => {
    return (
        
        <div className="row justify-content-center">
            <div className="col-1 justify-content-right pr-0">
                <div className="shape-img4 pl-5">

                
                </div>
            </div>
            <div className="col-2 pl-2">
                <p>Loading...</p>
            </div>
      </div>
    )
  }



class Transaction extends React.Component {



    // Minting function
    async wemint(tknumber,totalpayamount) {

        if (window.ethereum) {
            const web3 = new Web3(window.ethereum)
            const accounts = await web3.eth.getAccounts()
            //load balance
            if (typeof accounts[0] === 'undefined') {
                event.target.reset();
                this.props.addToast('Sorry, please connect the metamask', { appearance: 'error' });
                return
            }
        }

        //console.log("this.props.currentPrice --> ",this.props.currentPrice)
        //console.log("this.state.currentPrice --> ",this.props.currentPrice)
        //console.log("this.props.account --> ",this.props.account)
        //console.log("this.props.contract --> ",this.props.contract)


      if(this.props.contract!== 'undefined'){
        try{

          await this.props.contract.methods.superMint(tknumber).send({from: this.props.account,value: totalpayamount})

                  .once('receipt', (receipt) => {
                      this.setState({
                          action: "buy"
                      })
                  })


        } catch(e) {
          console.log('Error, withdraw: ', e)
        }
      }
    }



    constructor(props) {
       super(props);
       this.state = {
           account: '',
           isToggleOn: true,
           currentPrice: this.props.currentPrice,
           action: "wait"

       };

       // This binding is necessary to make `this` work in the callback    this.handleClick = this.handleClick.bind(this);
     }


render() {

    return (
        <section className="what-we-do-area bg-black pt-100 pb-70">

            <div className="container ">
                { this.props.currentPrice != 10? 
                    <div className="row">
                        <div className="col-sm-12">
                     
                            <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">

                                    <div className="feature feature-1 service-1 text-center">

                                        <h4>Current Price :{ this.props.currentPrice/(10**18)  } ETH</h4><hr/>

                                        <p align="left"> Please enter the quantity of tokens you would like to purchase (between 1 and 20 for each transaction)
                                        </p>


                                        <p align="left">



                                        <form onSubmit={(e) => {
                                            e.preventDefault()    //doesnt let the page refresh
                                            const tknumber = this.msize.value
                                            const totalPrice = tknumber *  this.props.currentPrice;
                                            //console.log('totalPrice: ', totalPrice/(10**18),"ETH")
                                        //    this.wemint(tknumber,totalPrice.toString())
                                            this.wemint(tknumber,totalPrice.toString())

                                            }}>

                                            <input
                                              type='text'
                                              className="form-control mb1"
                                              placeholder='e.g. 1-20'
                                              ref={(input) => {this.msize = input }}
                                              required />

                                            <input type='submit' className='btn btn-block btn-primary' value ='PURCHASE' />

                                            </form>



                                        </p>





                                        <hr/>
                                        </div>

                            </div>
                   
                    </div>
                </div>
                : <TransactionLoading/> }
            </div>


        </section>
    )
}
}

export default Presentation;
