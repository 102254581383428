import React from 'react';
import { useToasts } from 'react-toast-notifications';

import Web3 from 'web3';
import WithToast from '../Common/WithToast.js'

import axios from 'axios';


const VipwhitelistingLoading = (props) => {
    return (


        <div className="col-2 pl-2">
            <p>Loading ...</p>
        </div>

    )
}

const WhiteListingFormA = (props) => {

    return (
        <React.Fragment>

            <input
                type='hidden'
                name="authenticity_token"
                value={props.authenticity_token}

            />

            <input style={{ width:0, height:0 }}
                id="whitelist_withaddedvalue"
                type='hidden'
                name="whitelist[withaddedvalue]"
                value='true' />


            <div className="row justify-content-center align-items-center pb-1">
                <div className="col-2 col-sm-2 col-md-2 col-lg-1">
                    <label htmlFor="whitelist_address">ETH Address</label>
                </div>
                <div className="col-5">
                    <input
                        id="whitelist_address"
                        type="text"
                        className="form-control"
                        name="whitelist[address]"
                        value={props.account}
                        readOnly />
                    <p className="color-F2356D font-size-12px">The address is added automatically from Metamask.</p>
                </div>

            </div>

            <div className="row justify-content-center align-items-center pb-1">
                <div className="col-2 col-sm-2 col-md-2 col-lg-1">
                    <label htmlFor="whitelist_email">Email</label>
                </div>
                <div className="col-5">
                    <input
                        id="whitelist_email"
                        type="email"
                        className="form-control"
                        name="whitelist[email]"
                        placeholder='example@email.com'
                        />
                    <p className="color-F2356D font-size-12px">(Optional) If you'd like to get a reminder when pre-sale and sale happens.</p>
                </div>

            </div>

            <div className="row justify-content-center align-items-center pb-1">
                <div className="col-2 col-sm-2 col-md-2 col-lg-1">
                    <label htmlFor="whitelist_handle">Discord Handle</label>
                </div>
                <div className="col-5">
                    <input
                        id="whitelist_handle"
                        type="text"
                        className="form-control"
                        name="whitelist[handle]"
                        placeholder='Kai2088#2619'
                        required />
                    <p className="color-F2356D font-size-12px">(Required) Your Discord Handle so we can validate your activity scores and invites.</p>
                </div>

            </div>


            <div className="row justify-content-center align-items-center pb-1">
                <div className="col-2 col-sm-2 col-md-2 col-lg-1">
                    <label htmlFor="whitelist_comment_msg">Please choose</label>
                </div>
                <div className="col-5">
                    <input type="radio" id="msg1" name="whitelist_comment_msg" value="I have invites"  onChange={props.handleChange}  required/>
                    <label htmlFor="age1" className="pl-2"  >I have invites</label><br/>
                    <input type="radio" id="msg2" name="whitelist_comment_msg" value="I have high score"  onChange={props.handleChange}  />
                    <label htmlFor="age2" className="pl-2"  >I have high score</label><br/>
                    <p className="color-F2356D font-size-12px">(Required) Please choose how you qualified for the pre-sale whitelisting.</p>
                </div>

            </div>

            <div className="row justify-content-center align-items-center pb-1">
                <div className="col-2 col-sm-2 col-md-2 col-lg-1">
                    <label htmlFor="whitelist_comment">Comment</label>
                </div>
                <div className="col-5 ">
                    <textarea
                        id="whitelist_comment"
                        type="text"
                        className="form-control pt-0"
                        name="whitelist[comment]"
                        rows="3"
                        value={props.commendText}
                        onChange={props.handleChange}
                        placeholder='Comment' />

                    <p className="color-F2356D font-size-12px">(Optional) Please leave us a comment if you like</p>
                </div>

            </div>



            <div className="row justify-content-center align-items-center pb-1 pt-1">

                <div className="col-2 col-sm-2 col-md-2 col-lg-1">

                </div>
                <div className=".col-offset-1  col-5">
                    <input type='submit' className='btn btn-block default-btn-custom' value='Submit' />
                </div>
            </div>
        </React.Fragment>
    )
}

const WhiteListingFormB = (props) => {

    return (
        <React.Fragment>

            <input
                type='hidden'
                name="authenticity_token"
                value={props.authenticity_token}
            />

            <input
                id="whitelist_withaddedvalue"
                type='hidden'
                name="whitelist[withaddedvalue]"
                value='false' />

            <div className="row justify-content-center align-items-center pb-1">
                <div className="col-2 col-sm-2 col-md-2 col-lg-1">
                    <label htmlFor="whitelist_address">ETH Address</label>
                </div>
                <div className="col-5">
                    <input
                        id="whitelist_address"
                        type="text"
                        className="form-control"
                        name="whitelist[address]"
                        value={props.account}
                        readOnly
                        />
                     <p className="color-F2356D font-size-12px">The address is added automatically from Metamask.</p>
                </div>

            </div>

            <div className="row justify-content-center align-items-center pb-1">
                <div className="col-2 col-sm-2 col-md-2 col-lg-1">
                    <label htmlFor="whitelist_email">Email</label>
                </div>
                <div className="col-5">
                    <input
                        id="whitelist_email"
                        type="email"
                        className="form-control"
                        name="whitelist[email]"
                        placeholder='example@email.com'
                        />
                     <p className="color-F2356D font-size-12px">(Optional) If you'd like to get a reminder when pre-sale and sale happens.</p>
                </div>

            </div>


            <div className="row justify-content-center align-items-center pb-1">
                <div className="col-2 col-sm-2 col-md-2 col-lg-1">
                    <label htmlFor="whitelist_handle">Discord Handle</label>
                </div>
                <div className="col-5">
                    <input
                        id="whitelist_handle"
                        type="text"
                        className="form-control"
                        name="whitelist[handle]"
                        placeholder='Discord Handle'

                         />
                    <p className="color-F2356D font-size-12px">(Optional) Discord Handle</p>
                </div>

            </div>




            <div className="row justify-content-center align-items-center pb-1 pt-1">

                <div className="col-2 col-sm-2 col-md-2 col-lg-1">

                </div>
                <div className=".col-offset-1  col-5">
                    <input type='submit' className='btn btn-block default-btn-custom' value='Submit' />
                </div>
            </div>


        </React.Fragment>
    )
}

class Whitelisting extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            isToggleOn: true,
            emailAddress: "",
            discordHandle: "",
            ethAddress: "",
            statusPage: 0,
            commendText: "",
            account: "",
            accountexistsalready: false,
            checkAccDone: false,
            addedtocontractfromdatabase:false,
            addedtocontractfromBC:false
        };
        //console.log("authenticity_token")
        //console.log(this.props.authenticity_token)

        this.handleChange = this.handleChange.bind(this);
        this.updatePageStatus = this.updatePageStatus.bind(this);


    }

    handleChange = (e) => {

        this.setState({ commendText: e.target.value });
    };

    updatePageStatus(statusParam) {
        this.setState({ statusPage: statusParam })
    }



    async componentDidMount() {

        await this.loadWeb3(this.props.dispatch)
    }


    //network and web3 logic

    async loadWeb3(dispatch) {

      //console.log("loadWeb3")
      if (window.ethereum) {
        //console.log("Starting up....")
        const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
        const netId = await web3.eth.net.getId()     // gets the value of the network
        const enabledWeb3 = await ethereum.enable();
        const accounts = await web3.eth.getAccounts()

        //load balance
        if (typeof accounts[0] !== 'undefined') {
          //console.log("-----getting balance-----")

          this.setState({ account: accounts[0] })

          //check if this account is already registered in the DB, by calling the route
          const res = await axios.get("/checkaddress?ref="+this.state.account, {
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Content-Type'
            }
          });

          //console.log("res",res);

          ///checking the whitelisted status of the address directly in the blockchain
          // using the contract feeding as props

          const addedtocontractfromBC = await this.props.contract.methods.whitelist(this.state.account).call().catch(error => {
          });
          this.setState({ addedtocontractfromBC })
          //console.log("getting info from BC in whitelisting module. (not page)", this.state.addedtocontractfromBC )



          if (res["data"] == null) {
              this.setState({accountexistsalready: false})
          }
          else
          {
               this.setState({accountexistsalready: true})

               this.setState({addedtocontractfromdatabase: res["data"]["addedtocontract"] })

          };

          this.setState({checkAccDone: true})


         //console.log("this.state.accountexistsalready", this.state.accountexistsalready );
         //console.log("this.state.addedtocontractfromdatabase", this.state.addedtocontractfromdatabase );

        } else {
          window.alert('Please login with MetaMask')
          this.setState({checkAccDone: false})
        }


    }   // END OF LOADING block chain data

    }


    render() {

        return (
            <React.Fragment>

                <section className="what-we-do-area bg-black pt-100 pb-100">

                    <div className="container ">




                        {
                           this.state.checkAccDone == true || (this.state.addedtocontractfromBC == true)?
                                this.state.statusPage == 0 ?
                                    this.state.accountexistsalready == true || (this.state.addedtocontractfromBC == true)?
                                        <div className="row justify-content-center">
                                        <div className="col-10  pt-5">
                                            <div className="d-flex flex-row text-center  justify-content-center">
                                            <div>CURRENT STATUS: <span className="color-F2356D" >{this.state.addedtocontractfromBC? " You are whitelisted to participate in the pre-sale.":"Your demand to join the Whitelist has been received, but you are not whitelisted yet." } </span> </div>
                                            </div>

                                        </div>
                                        </div>

                                        :

                                        <div className="row justify-content-center">
                                            <div className="col-4 px-0" >
                                                <div className="">
                                                        <img src={this.props.S3_ADDRESS+"website/howtoWL.jpg" } alt="image" style={{maxWidth:"120%"}} />
                                                </div>
                                            </div>
                                            <div className="col-7 px-0">
                                                <div className="d-flex flex-column text-center  justify-content-center align-items-center" >
                                                    <button type="button" onClick={() => this.updatePageStatus(1)} className="default-btn-custom m-5 ">I HAVE 6 DISCORD INVITES OR I HAVE WON A COLLAB</button>
                                                    <button type="button" onClick={() => this.updatePageStatus(2)} className="default-btn-custom m-5 ">I DO NOT HAVE INVITES BUT WANNA GET LUCKY</button>
                                                </div>

                                            </div>
                                        </div>

                                :

                                <form action="/whitelisting" method="post" onSubmit={(e) => {

                                    //console.log(this.authenticity_token.value)
                                    // if (this.state.account == ""){
                                    //     e.preventDefault()
                                    //     this.props.addToast('Please login with MetaMask', { appearance: 'error' });
                                    // }
                                    //this.props.authenticity_token
                                }}>
                                    {this.state.statusPage == 1 ?

                                    <WhiteListingFormA account={this.state.account} commendText={this.state.commendText} handleChange={this.handleChange} authenticity_token={this.props.authenticity_token} />

                                    :

                                    <WhiteListingFormB account={this.state.account} authenticity_token={this.props.authenticity_token}/>

                                    }
                                </form>

                            :

                            <div className="row justify-content-center">
                                <div className="col-10  pt-5">
                                    <div className="d-flex flex-row text-center  justify-content-center">
                                    <div><p style={{color:"white"}}>Please connect with Metamask</p></div>
                                    </div>
                                </div>
                            </div>
                        }




                    </div>


                </section>

            </React.Fragment>
        )
    }
}
// Specifies the default values for props:
Whitelisting.defaultProps = {
    vipAccessPrice: 10
};


export default WithToast(Whitelisting);
