import React, { useState } from 'react';
import ReactDOM from "react-dom";
import PropTypes from "prop-types"
import CountDown from './CountDown';
import '../../stylesheets/custom'
import '../../stylesheets/custom.css'

// import Percent20 from "./Percent20"
// import Percent40 from "./Percent40"
// import Percent60 from "./Percent60"
//import Percent80 from "./Percent80"
// import Percent100 from "./Percent100"

import Percent25 from "./Percent25"
import Percent50 from "./Percent50"
import Percent75 from "./Percent75"
import Percent100 from "./Percent100_2"

import testMP4 from './../../../videos/test.mp4'


const Solutions4 = (props) => {

    const [onilne, setOnline] = useState(false);


    return (
        <React.Fragment>
            <section className="projects-area bg-color-black pt-5 pb-5 ">
                <div className="container border-line-bottom ">
                    <div className="row justify-content-center mt-1 ">

                        <React.Fragment>
                            <div className="col-12 pb-3 ">
                                <div className="d-flex flex-column justify-content-center" >
                                    <h2 className="rockwell-font pb-3 color-FFFFFF  pb-4" style={{ fontSize: '52px' }}>
                                        THE
                                            <span style={{ color: '#F2356D' }}> ROADMAP AND UTILITY</span>
                                    </h2>

                                    <div className="d-flex flex-row  mb-3">
                                        <div className="d-flex flex-even flex-column align-content-start align-items-center mx-1">
                                            <div className="d-flex rounded-full justify-content-center align-items-center" style={{ width: "100px", height: "100px"}} >
                                                <img src={props.S3_ADDRESS + "website/room.png"}  alt="room.png" className=""></img>
                                            </div>
                                            <div className="justify-content-center text-center color-FFFFFF  pt-2">25 %</div>
                                            <div className="justify-content-center text-center color-FFFFFF " style={{ width:"100%", paddingTop: "0.1rem", paddingBottom: "0.1rem", backgroundColor:"#F2356D", fontWeight: "bold"}}>INFINITE OBJECTS DROP</div>
                                            <ul className="pl-4" style={{ listStyleType: "disc" }}>
                                                <li className="mt-1 color-FFFFFF  text-center">We're sending 5 Infinite Objects NFT Displays to Holders.</li>
                                                <li className="mt-1 color-FFFFFF  text-center">Admire your NFTs right in front of you IRL.</li>
                                            </ul>

                                        </div>
                                        <div className="d-flex flex-even flex-column  align-content-start align-items-center mx-1">
                                            <div className="d-flex rounded-full justify-content-center align-items-center" style={{ width: "100px", height: "100px"}} >
                                                <img src={props.S3_ADDRESS + "website/computer.png"}  alt="room.png" className=""></img>
                                            </div>
                                            <div className="justify-content-center text-center color-FFFFFF  pt-2">50 %</div>
                                            <div className="justify-content-center text-center color-FFFFFF " style={{ width:"100%", paddingTop: "0.1rem", paddingBottom: "0.1rem", backgroundColor:"#F2356D", fontWeight: "bold"}}>DERIVATIVES ART COMP.</div>
                                            <ul className="pl-4" style={{ listStyleType: "disc" }}>
                                                <li className="mt-1 color-FFFFFF  text-center">Talents run wild with Derivatives Art comp with NFT prizes.</li>
                                                <li className="mt-1 color-FFFFFF  text-center">Community will vote on most talented artists among us.</li>
                                            </ul>
                                        </div>
                                        <div className="d-flex flex-even flex-column  align-content-start align-items-center mx-1">
                                            <div className="d-flex rounded-full justify-content-center align-items-center" style={{ width: "100px", height: "100px"}} >
                                                <img src={props.S3_ADDRESS + "website/upgrade.png"}  alt="room.png" className=""></img>
                                            </div>
                                            <div className="justify-content-center text-center color-FFFFFF  pt-2">75 %</div>
                                            <div className="justify-content-center text-center color-FFFFFF " style={{ width:"100%", paddingTop: "0.1rem", paddingBottom: "0.1rem", backgroundColor:"#F2356D", fontWeight: "bold"}}>FLOOR SUPPORT</div>
                                            <ul className="pl-4" style={{ listStyleType: "disc" }}>
                                                <li className="mt-1 color-FFFFFF  text-center">We set aside 10 ETH to buy niftycash NFTs at floor for giveaways.</li>
                                                    <li className="mt-1 color-FFFFFF  text-center">Proven mechanism to mitigate paperhands effect.</li>
                                            </ul>
                                        </div>
                                        <div className="d-flex flex-even flex-column  align-content-start align-items-center mx-1">
                                            <div className="d-flex rounded-full justify-content-center align-items-center" style={{ width: "100px", height: "100px"}} >
                                                <img src={props.S3_ADDRESS + "website/liquidity.png"}  alt="room.png" className=""></img>
                                            </div>
                                            <div className="justify-content-center text-center color-FFFFFF  pt-2">100 %</div>
                                            <div className="justify-content-center text-center color-FFFFFF " style={{ width:"100%", paddingTop: "0.1rem", paddingBottom: "0.1rem", backgroundColor:"#F2356D", fontWeight: "bold"}}>CHARITY</div>
                                            <ul className="pl-4" style={{ listStyleType: "disc" }}>
                                                <li className="mt-1 color-FFFFFF  text-center">We will donate 15,000USD to a Charity voted by the community from the team's pre-selection.</li>
                                                <li className="mt-1 color-FFFFFF  text-center">Utility: We use part of secondary market fees to raffle Blue Chip NFTs to Holders. Get Lucky with us.</li>

                                            </ul>
                                        </div>

                                        <div className="d-flex flex-even flex-column  align-content-start align-items-center mx-1">
                                            <div className="d-flex rounded-full justify-content-center align-items-center" style={{ width: "100px", height: "100px"}} >
                                                <img src={props.S3_ADDRESS + "website/charity.png"} alt="room.png" className=""></img>
                                            </div>
                                            <div className="justify-content-center text-center color-FFFFFF  pt-2">3M after drop</div>
                                            <div className="justify-content-center text-center color-FFFFFF " style={{ width:"100%", paddingTop: "0.1rem", paddingBottom: "0.1rem", backgroundColor:"#F2356D", fontWeight: "bold"}}>CW2088 RE-LOADED</div>
                                            <ul className="pl-4" style={{ listStyleType: "disc" }}>
                                                <li className="mt-1 color-FFFFFF  text-center">New collection release with more dope and cutting-edge Art.</li>
                                                <li className="mt-1 color-FFFFFF  text-center">Special early access and discounts for niftycash Holders.</li>
                                                <li className="mt-1 color-FFFFFF  text-center">Utility: Early bird access and FREE drops for qualified holders</li>

                                            </ul>
                                        </div>

                                    </div>


                                </div>



                            </div>

                        </React.Fragment>



                    </div>

                </div>
            </section>

        </React.Fragment>
    )
}

Solutions4.propTypes = {
    artWorksData: PropTypes.array,
    S3_ADDRESS: PropTypes.string
};

export default Solutions4;
