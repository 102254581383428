import React from "react"
import PropTypes from "prop-types"

//imported specially for this page
import PageBanner from './Common/PageBanner';
import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
import FooterMin from './_App/FooterMin';
import {  RecoilRoot } from 'recoil'
//import OwnCollectionv1 from './OwnCollectionv1';
import OwnCollectionMKoKeShi from './OwnCollectionMKoKeShi';
import { ToastProvider } from 'react-toast-notifications';
//for web3

import Web3 from 'web3';


class MyCollection extends React.Component {


    constructor(props) {
      super(props);
      this.state = {
          account: '',
          isEmpty: true,
          processForm: false

      };
      this.handlerForms = this.handlerForms.bind(this);
      this.isEmpty = this.isEmpty.bind(this);
    }

    handlerForms(status){
      this.setState({
        processForm: status
      })
    }

    isEmpty(status){
      this.setState({
        isEmpty: status
      })
    }

    //mounting the main Element
    async componentDidMount() {
      await this.loadWeb3(this.props.dispatch)

    }

    //network and web3 logic

       async loadWeb3(dispatch) {
         //console.log("loadWeb3")

         if(window.ethereum){

           //console.log("Starting up Collectopm....")
           const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
           const netId = await web3.eth.net.getId()     // gets the value of the network commenting
           const enabledWeb3 = await ethereum.enable();
           const accounts = await web3.eth.getAccounts()

           //load balance
           if(typeof accounts[0] !=='undefined'){

               this.setState({
                   account: accounts[0]
               });

           } else {
             //window.alert('Please login with MetaMask')

           }


         } else {
           window.alert('Please install MetaMask')
         }
     }   // END OF LOADING block chain data



  render () {
    return (
      <React.Fragment>
      <RecoilRoot>
      <NavbarTwoCustom/>
      <PageBanner
          pageTitle= {"Your Rioters" }
          homePageText="ETH Address"
          homePageUrl="/"
          activePageText={this.state.account}
          tag={true}
      />

        <ToastProvider autoDismiss autoDismissTimeout={10000}>
        <OwnCollectionMKoKeShi
            S3_ADDRESS = {this.props.S3_ADDRESS}
            tokensRevealed = {this.props.tokensRevealed}
            namingStartTimestamp = {this.props.namingStartTimestamp}
            stateForms={this.state.processForm}
            handlerForms = {this.handlerForms}
            setEmpty={this.isEmpty}
            isEmpty={this.state.isEmpty}
         />
         </ToastProvider>

          <FooterMin fixedBottom = {this.state.isEmpty} />
          <GoTop />
          </RecoilRoot>

      </React.Fragment>

    );
  }
}

MyCollection.propTypes = {
  tokensRevealed: PropTypes.bool,
  S3_ADDRESS: PropTypes.string
};
export default MyCollection
