import React from 'react';
//import Web3 from 'web3';
import '../stylesheets/custom.css';
import WithToast from '../components/Common/WithToast.js';

class RenamingBoxes extends React.Component {



  //Renaming function
  async werename(tokenId, newName, event) {


    if (this.props.contract !== 'undefined') {
      try {
        const addToast = this.props.addToast
        const removeAllToasts = this.props.removeAllToasts
        console.log("trying to rename - Need original token ID here, even after reveal", tokenId, newName)
        await this.props.contract.methods.changeName(tokenId, newName).send({ from: this.props.account })
          .on('transactionHash', function (hash) {
            removeAllToasts();
            addToast('Processing...', { appearance: 'info', autoDismiss: false });

          })
          .once('receipt', (receipt) => {
            removeAllToasts()
            window.location.search = '?success';
          })



      } catch (e) {
        event.target.reset();
        //console.log('Error, withdraw: ', e)
        this.props.updateFormState(false);
        this.props.removeAllToasts();
        this.props.addToast('Error, renaming the collection', { appearance: 'error' });
      }
    }
  }

  handleSubmit(event) {

    event.preventDefault()    //doesnt let the page refresh

    this.setState({
      disabledInputs: true
    });

    this.props.updateFormState(true);
    const tkname = this.newname.value;

    // setTimeout(() => {
    //   console.log('Interval updateFormState setTimeout');
    //   this.props.updateFormState(false);
    //   event.target.reset();
    // }, 5000);

    this.werename(this.props.realid, tkname, event)
  }

  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      action: "wait",
      disabledInputs: props.isDisable
    };


    // This binding is necessary to make `this` work in the callback    this.handleClick = this.handleClick.bind(this);
    this.werename = this.werename.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const successParam = urlParams.has('success')
    if (successParam) {
      window.history.pushState(null, null, window.location.pathname);
      this.props.addToast('Renaming successful', { appearance: 'success' });
    }


  }


  render() {
    return (

      <React.Fragment>

        <div className="events-details-info size-details-info">
          <ul className="info">
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <span>Token ID :</span>{this.props.realid}
              </div>
            </li>


            {this.props.tokenName == "" ?

              <li style={{ "borderBottom": "0px" }}>
                <div className="justify-content-between align-items-center">
                  {(Math.floor(Date.now() / 1000)) > this.props.namingStartTimestamp ?
                    <div>
                      <form onSubmit={this.handleSubmit}>

                        <input
                          type='text'
                          className="form-control mb-2 col-xs-4"
                          placeholder='e.g. All the way to the moon!'
                          ref={(input) => { this.newname = input }}
                          disabled={this.props.stateForms}
                          required />

                        <input type='submit' className='btn btn-block btn-primary' value='RENAME' ref={(input) => { this.submitBtn = input }} disabled={this.props.stateForms}
                        />

                      </form>
                    </div>

                    :

                    <div>Tokens can be named 4 days after the sale start.</div>

                  }
                </div>
              </li>

              :

              ""

            }



          </ul>

        </div>


      </React.Fragment>



    )
  }
}

export default WithToast(RenamingBoxes);
