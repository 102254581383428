import React from 'react';

const Team = (props) => {
    return (
        <section className="what-we-do-area bg-color-black pt-4 pb-5">

            <div className="container ">
                <div className="row justify-content-center ">

                    <div className="col-md-12 col-md-push-1 col-sm-12 col-sm-push-1">

                        <h2 className="color-FFFFFF  rockwell-font text-uppercase pb-4" style={{ fontSize: '52px', fontWeight: '400' }}>Meet the <span className="color-F2356D">Team</span></h2>

                        <div className="container ">
                            <div className="row ">
                                <div className="col-12  col-sm-12 col-md-6 pb-5">
                                    <div className="row justify-center pb-5">
                                        <a href="" className="col-12 col-sm-6 col-md-6  mx-auto mb-4 hover:cursor-pointer" target="_blank" rel="noreferrer">
                                            <img src={props.S3_ADDRESS+"website/seed0235.pngres.png"} style={{ minWidth: '200px' }} alt="Nomanz" />
                                        </a>
                                        <div className="col-12 col-sm-6 col-md-6 d-flex flex-column ">
                                            <h2 className="rockwell-font color-FFFFFF ">Modimany</h2>
                                            <p className="font-main " style={{ fontSize: '18px', fontWeight: '400', color: 'gray' }} >Founder</p>
                                            <p className="lg:text-sm font-main " style={{ fontSize: '14px', fontWeight: '400', }} > Taking part in the crypto revolution through Art and Technology. I have talent with a keyboard but unfortunately less so with a paintbrush. Putting together teams and identifying talents is my way to give back to life.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12  col-sm-12 col-md-6 pb-5">
                                    <div className="row justify-center pb-5">
                                        <a href="" className="col-12 col-sm-6 col-md-6 mx-auto mb-4 hover:cursor-pointer" target="_blank" rel="noreferrer">
                                            <img src={props.S3_ADDRESS+"website/seed0524.pngres.png"} style={{ minWidth: '200px' }} alt="skitzzoSigned" />
                                        </a>
                                        <div className="col-12 col-sm-6 col-md-6  d-flex flex-column ">
                                            <h2 className="rockwell-font color-FFFFFF ">Salvatore</h2>
                                            <p className="font-main " style={{ fontSize: '18px', fontWeight: '400', color: 'gray' }} >Discord Beast</p>
                                            <p className="lg:text-sm font-main " style={{ fontSize: '14px', fontWeight: '400', }} >I do my best to make your Discord experience a pleasure. I am your go-to guy if you have any questions. When not looking at NFTs, I like to chill with Netflix!</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12  col-sm-12 col-md-6 pb-5 ">
                                    <div className="row justify-center pb-5">
                                        <a href="" className="col-12 col-sm-6 col-md-6 mx-auto mb-4 hover:cursor-pointer" target="_blank" rel="noreferrer">
                                            <img src={props.S3_ADDRESS+"website/dago.png"}  style={{ minWidth: '200px' }} alt="skitzzoSigned" />
                                        </a>
                                        <div className="col-12 col-sm-6 col-md-6 d-flex flex-column ">
                                            <h2 className="rockwell-font color-FFFFFF ">D'Agostino AI</h2>
                                            <p className="font-main " style={{ fontSize: '18px', fontWeight: '400', color: 'gray' }} >Front Artist</p>
                                            <div className="d-flex flex-column">
                                                <p className="lg:text-sm font-main " style={{ fontSize: '14px', fontWeight: '400', }} >Artist with a tech background. I create as much with my hands as with my mind. For reference, please have a look at my Instagram here:</p>
                                                Socials (Dagostinoai): 
                                                <div className="d-flex flex-row">
                                                    <a href="https://www.instagram.com/dagostinoai/" className="d-block" target="_blank">
                                                        <i className='bx bxl-instagram bx-small-custom'></i>
                                                    </a>
                                                    <a href="https://twitter.com/dagostinoai/" className="d-block" target="_blank">
                                                        <i className='bx bxl-twitter bx-small-custom'></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12  col-sm-12 col-md-6 pb-5 ">
                                    <div className="row justify-center pb-5">
                                        <a href="" className="col-12 col-sm-6 col-md-6 mx-auto mb-4 hover:cursor-pointer" target="_blank" rel="noreferrer">
                                            <img src={props.S3_ADDRESS+"website/1Y0POQ0I.png"} style={{ minWidth: '200px' }} alt="skitzzoSigned" />
                                        </a>
                                        <div className="col-12 col-sm-6 col-md-6  d-flex flex-column ">
                                            <h2 className="rockwell-font color-FFFFFF ">Aiwa</h2>
                                            <p className="font-main " style={{ fontSize: '18px', fontWeight: '400', color: 'gray' }} >Chief Hawk</p>
                                            <p className="lg:text-sm font-main " style={{ fontSize: '14px', fontWeight: '400', }} > I make sure that the bad guys don't reach our Discord server. Good guys in, bad guys kick! When not chasing bandits, I play Alix Half Life and raise my lovely family.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12  col-sm-12 col-md-6 pb-5">
                                    <div className="row justify-center pb-5">
                                        <a href="" className="col-12 col-sm-6 col-md-6 mx-auto mb-4 hover:cursor-pointer" target="_blank" rel="noreferrer">
                                            <img src={props.S3_ADDRESS+"website/3ZD8P8QA.png"} style={{ minWidth: '200px' }} alt="skitzzoSigned" />
                                        </a>
                                        <div className="col-12 col-sm-6 col-md-6 d-flex flex-column ">
                                            <h2 className="rockwell-font color-FFFFFF ">Hansen</h2>
                                            <p className="font-main " style={{ fontSize: '18px', fontWeight: '400', color: 'gray' }} >Marketing</p>
                                            <p className="lg:text-sm font-main " style={{ fontSize: '14px', fontWeight: '400', }} >Sending messages to the world at large is my job. If you are reading this, it is likely you have read something I came up with. I will be so glad if you re-tweet, invite and click like - This is my life :) Thanks so much!</p>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-12  col-sm-12 col-md-6 pb-5 ">
                                    <div className="row justify-center pb-5">
                                        <a href="" className="col-12 col-sm-6 col-md-6 mx-auto mb-4 hover:cursor-pointer" target="_blank" rel="noreferrer">
                                            <img src={props.S3_ADDRESS+"website/4SECO7AZ.png"} style={{ minWidth: '200px' }} alt="skitzzoSigned" />
                                        </a>
                                        <div className="col-12 col-sm-6 col-md-6  d-flex flex-column ">
                                            <h2 className="rockwell-font color-FFFFFF ">Pen</h2>
                                            <p className="font-main " style={{ fontSize: '18px', fontWeight: '400', color: 'gray' }} >Partnerships</p>
                                            <p className="lg:text-sm font-main " style={{ fontSize: '14px', fontWeight: '400', }} > Hello - My main job on the project is to find significative and impactful partnerships to push the brand to the max. When you see a Mutant Money Society bank note on the cover of Time magazine, it will be because of me. </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>



        </section>
    )
}

export default Team;
