import React from 'react';
import '../../utils/flipdown.css';
import FlipDown from '../../utils/flipdown.js';

class CountDown extends React.Component {
    constructor(props) {
      super(props);
      this.state = { isOnline: true };
    }

    handleCountdown = () =>{

                // Unix timestamp (in seconds) to count down to //1623308400
                var targetDate = (this.props.SALE_START_TIMESTAMP);
                // var targetDate = (new Date().getTime() / 1000) + 10 ;
                var currentDate = new Date().getTime() / 1000;
                var date_distance = (targetDate - currentDate )
                if (date_distance > 0) {
                  // Set up FlipDown
                  var flipdown = new FlipDown(targetDate, {
                    theme: "light",
                  })

                  // Start the countdown
                  .start()

                  // Do something when the countdown ends
                  .ifEnded(() => {
                    this.setState({ isOnline: false })
                    this.props.setOnilne(true)
                  });
                }
                else {
                    // setState to True
                    this.setState({ isOnline: false })
                    this.props.setOnilne(true)

                }

    }

    componentDidMount() {

            this.handleCountdown()


    }

    render() {

      return (
        <React.Fragment>
            <div id="flipdownPanel" >
            { this.state.isOnline ?
                <div id="flipdown" className="flipdown flipdown__theme-dark"></div>
            :
                  <div className="btn-box animate__fadeInLeft pt-5">
                    <a href="/transactions" className="default-btn" >
                        <i className="flaticon-right"></i>
                        Start Collecting
                        <span></span>
                    </a>
                  </div>}
            </div>
        </React.Fragment>
      )


    }
  }



export default CountDown;
