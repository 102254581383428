import React from 'react';
import Web3 from 'web3';



const WithdrawLoading = (props) => {
  return (

    <div className="row justify-content-center">
      <div className="col-1 justify-content-right pr-0">
        <div className="shape-img4 pl-5">


        </div>
      </div>
      <div className="col-2 pl-2">
        <p>Loading...</p>
      </div>
    </div>
  )
}

class Withdrawals extends React.Component {

  //Withdrawing function
  async wewithdraw() {

    console.log("<-- INSIDE WITHDRAWALS --> ")
    console.log("this.props.contractBalance --> ", this.props.contractBalance)

    if (this.props.contract !== 'undefined') {
      try {
        1
        await this.props.contract.methods.withdraw().send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      contractBalance: this.props.contractBalance,
      action: "wait"

    };

    // This binding is necessary to make `this` work in the callback    this.handleClick = this.handleClick.bind(this);
  }


  render() {
    return (
      <section className="what-we-do-area bg-black pt-100 pb-70">

        <div className="container ">
          {this.props.ownerAccount != null ?
            <div className="row justify-content-center">

              <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">

                <div className="feature feature-1 service-1 text-center">

                  <h4>Contract Balance : {this.props.contractBalance / (10 ** 18)} ETH -- Action {this.state.action} </h4><hr />

                  <p align=""> Just click to withdraw the ETH to : {this.props.ownerAccount}
                  </p>






                  <form onSubmit={(e) => {
                    e.preventDefault()    //doesnt let the page refresh

                    this.wewithdraw()

                  }}>
                    <div className="form-row justify-content-center">
                      <div className="form-group col-4 ">
                        <input
                          type='text'
                          className="form-control mb-3"
                          placeholder='Nothing to write'
                          ref={(input) => { this.msize = input }}
                        />

                        <input type='submit' className='btn btn-block btn-primary' value='WITHDRAW Balance' />
                      </div>
                    </div>
                  </form>

                  <hr />
                </div>

              </div>


            </div>
            : <WithdrawLoading />}
        </div>


      </section>
    )
  }
}

export default Withdrawals;
