import React, { useState } from 'react';
import PropTypes from "prop-types"
import CountDown from './CountDown';
import '../../stylesheets/custom'

const ArtWorkGenerator = (props) => {

    return (

        <div className="row justify-content-center">
            {props.artWorksData.map(artWork =>
                <div className="column " key={artWork.id}>
                    <img src={props.S3_ADDRESS + "artworks/" + artWork.internalname} alt="image" className="img1" />
                </div>
            )}
        </div>

    );
}


const ArtWorkGeneratorReloaded = (props) => {

    return (

        <div className="row justify-content-center">
            <div className="column ">
                <img src={props.S3_ADDRESS + "webimages/4x2.png"} alt="image" />
            </div>

        </div>

    );
}

const ContractNFT = (props) => {

    const [onilne, setOnline] = useState(false);




    return (
        <React.Fragment>
            <section className="projects-area bg-color-black ">
                <div className="container bg-color-black pt-4 border-line-bottom pb-70">

                    <div className="row justify-content-center mt-2">
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 pb-2">
                            <div className="d-flex justify-content-center">

                                
                                    <div className="stage-name" style={{ color: 'white',fontSize: '16px' }}>Contract address :  <b>0x68eC932CCb3B1aac03766A3F2f3D90B7603f2Cd3</b> </div>
                               
                            </div>
                        </div>
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 pb-2">
                            <div className="d-flex justify-content-center">


                                <a href="https://etherscan.io/token/0x68eC932CCb3B1aac03766A3F2f3D90B7603f2Cd3" target="_blank" >
                                    <div className="stage-name" style={{ color: 'white',fontSize: '16px' }}><b>See it on etherscan</b> </div>
                                </a>

                            </div>
                        </div>
                    </div>
                    <br />

                </div>
            </section>

        </React.Fragment>
    )
}

ContractNFT.propTypes = {
    artWorksData: PropTypes.array,
    S3_ADDRESS: PropTypes.string
};

export default ContractNFT;
