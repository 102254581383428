import React from 'react';

const Percent40 = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={props.width} height={props.height} viewBox="0 0 980.000000 982.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,982.000000) scale(0.100000,-0.100000)"
        fill={props.color} stroke="none">
        <path d="M4445 9799 c-330 -31 -740 -118 -1075 -229 -794 -261 -1491 -708
-2057 -1319 -642 -692 -1048 -1489 -1232 -2416 -67 -336 -87 -613 -78 -1065 8
-348 18 -471 62 -710 91 -494 228 -907 449 -1349 249 -496 510 -855 926 -1271
371 -372 680 -603 1125 -843 511 -275 1017 -447 1595 -542 201 -33 401 -45
740 -45 338 0 539 12 737 45 1218 200 2263 791 3036 1718 581 696 943 1512
1092 2461 47 297 46 1060 0 1352 -170 1062 -605 1966 -1290 2684 -501 524
-1033 894 -1675 1165 -373 157 -926 306 -1285 344 -263 29 -860 40 -1070 20z
m635 -609 c294 -13 409 -24 580 -56 937 -175 1751 -622 2383 -1309 643 -699
1003 -1477 1122 -2425 22 -173 31 -638 16 -826 -111 -1391 -878 -2621 -2096
-3359 -138 -84 -453 -239 -605 -298 -316 -123 -662 -215 -980 -261 -253 -37
-737 -43 -1060 -15 -470 41 -1038 214 -1520 463 -556 286 -1071 723 -1453
1232 -249 332 -465 733 -612 1139 -85 235 -176 601 -209 843 -37 268 -40 866
-5 1142 77 617 331 1293 682 1820 266 398 629 780 1012 1066 641 478 1392 766
2215 847 77 8 307 6 530 -3z"/>
        <path d="M4605 6434 c-293 -46 -507 -277 -563 -606 -15 -92 -17 -188 -16 -908
1 -535 5 -838 13 -903 25 -215 126 -408 271 -518 211 -159 548 -185 800 -61
95 46 221 167 273 262 112 205 133 420 123 1300 -8 803 -18 906 -101 1079 -84
173 -239 297 -420 336 -70 15 -323 28 -380 19z m261 -356 c53 -22 102 -68 125
-114 20 -42 23 -69 31 -293 5 -135 7 -579 5 -986 -4 -797 -5 -812 -57 -883
-40 -54 -80 -67 -211 -67 -111 0 -117 1 -157 29 -101 70 -104 104 -100 1226 3
817 5 889 22 938 23 70 87 135 153 156 53 17 142 15 189 -6z"/>
        <path d="M2908 6408 c-13 -11 -245 -593 -643 -1618 -215 -551 -245 -649 -245
-790 l0 -70 500 0 500 0 0 -87 c0 -49 3 -172 7 -275 l6 -188 207 0 207 0 6
107 c4 58 7 182 7 275 l0 168 135 0 135 0 0 185 0 185 -133 0 -134 0 -5 1051
c-5 871 -8 1053 -19 1060 -23 14 -512 11 -531 -3z m146 -565 c4 -27 -2 -334
-14 -683 -11 -349 -20 -686 -20 -747 l0 -113 -247 0 c-137 0 -253 4 -259 8
-13 8 0 50 298 957 104 319 196 590 204 603 22 33 31 27 38 -25z"/>
        <path d="M6189 6391 c-158 -51 -248 -184 -270 -399 -15 -146 -6 -489 15 -565
38 -136 118 -237 222 -277 50 -20 78 -24 179 -24 102 0 129 3 178 23 76 30
142 97 195 198 57 110 65 171 60 477 -4 243 -5 253 -30 313 -64 151 -144 231
-260 258 -90 20 -218 19 -289 -4z m215 -215 c24 -10 38 -27 56 -67 23 -51 25
-67 28 -287 5 -324 -13 -440 -74 -473 -14 -7 -51 -13 -81 -13 -49 0 -58 3 -84
31 -46 52 -52 89 -57 358 -7 387 16 462 141 464 21 1 52 -5 71 -13z"/>
        <path d="M7212 6383 c-33 -3 -45 -10 -52 -26 -12 -26 -46 -153 -282 -1042
-308 -1163 -329 -1248 -315 -1257 6 -4 60 -8 119 -8 l107 0 15 53 c8 28 119
446 246 927 225 854 346 1307 356 1343 5 15 -3 17 -73 15 -43 -1 -97 -3 -121
-5z"/>
        <path d="M7495 5316 c-122 -30 -209 -104 -269 -228 -30 -63 -38 -93 -47 -184
-12 -118 -6 -428 11 -528 22 -134 106 -253 218 -308 64 -32 71 -33 187 -33
120 0 122 0 198 38 120 59 183 150 223 322 15 65 19 119 19 285 0 209 -7 265
-46 385 -62 194 -279 304 -494 251z m189 -239 c32 -32 46 -56 54 -93 19 -83
23 -210 14 -425 -10 -229 -16 -252 -82 -296 -69 -47 -150 -20 -191 64 -23 47
-24 54 -24 313 0 146 4 291 8 323 10 65 39 107 96 137 55 29 76 25 125 -23z"/>
      </g>
    </svg>
  );
}
export default Percent40;