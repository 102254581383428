import React from 'react';
import { useToasts } from 'react-toast-notifications';

import Web3 from 'web3';
import WithToast from '../Common/WithToast.js'
import mutantmoneysociety from './../../contracts/Mutantmoneysociety.json'



const VipwhitelistingLoading = (props) => {
    return (

        <div className="row justify-content-center">
            <div className="col-1 justify-content-right pr-0">
                <div className="shape-img4 pl-5">


                </div>
            </div>
            <div className="col-2 pl-2">
                <p>Loading 33...</p>
            </div>
        </div>
    )
}

class Vipwhitelisting extends React.Component {

    // Minting function
    async weVIPWhitelist( event) {

        if (window.ethereum) {
            const web3 = new Web3(window.ethereum)
            const accounts = await web3.eth.getAccounts()
            //load balance
            if (typeof accounts[0] === 'undefined') {
                event.target.reset();
                this.props.addToast('Sorry, please connect the metamask', { appearance: 'error' });
                return
            } else {

                this.props.updateAccount(accounts[0])

            }
        }

        //console.log("this.state.vipAccessPrice --> ", this.state.vipAccessPrice)
        //console.log("this.props.account --> ", this.props.account)
        //console.log("this.props.contract --> ", this.props.contract)



        if (this.props.contract !== 'undefined') {
            try {

                await this.props.contract.methods.VIPAddAddressToWhiteList().send({ from: this.props.account, value: this.state.vipAccessPrice })
                    .on('transactionHash', function (hash) {
                        //console.log("transactionHash State")

                        window.location.search = '?success';
                    })
                    .once('receipt', (receipt) => {
                        this.setState({
                            action: "buy"
                        })
                        event.target.reset();
                    })


            } catch (e) {
                event.target.reset();
                this.props.addToast('Sorry, there has been an error', { appearance: 'error' });
                console.log('Error, withdraw: ', e)
            }
        }
    }

    //code to check the counters


    constructor(props) {
        super(props);

        this.state = {
            isToggleOn: true,
            action: "wait",
            vipAccessPrice: 10000000000000000,
            vipWhitelisted: false,
            account: ""

        };

        // This binding is necessary to make `this` work in the callback    this.handleClick = this.handleClick.bind(this);
    }

    //network and web3 logic

    async loadWeb3(dispatch) {
      //console.log("loadWeb3")
      if (window.ethereum) {

        //console.log("Starting up....")
        const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
        const netId = await web3.eth.net.getId()     // gets the value of the network
        const enabledWeb3 = await ethereum.enable();
        const accounts = await web3.eth.getAccounts()

        if (typeof accounts[0] !== 'undefined') {

          this.setState({ account: accounts[0]})

        } else {
          window.alert('Please login with MetaMask')
        }


        //load contracts
        try {


          //console.log("mutantmoneysociety.networks[netId].address  ->",mutantmoneysociety.networks[netId].address)

          const contract = new web3.eth.Contract(mutantmoneysociety.abi, mutantmoneysociety.networks[netId].address)  // comes from ABI json file

          //get the current price
          const vipAccessPrice = await contract.methods.VIPAccessPrice().call().catch(error => {
            return web3.utils.toWei('1000', 'ether')
          });

          this.setState({ vipAccessPrice })
          //console.log("current VIPAccessPrice inside the VIPWHITELISTING", web3.utils.fromWei(vipAccessPrice, 'ether'), "ETH")


          //check if that account is whitelisted -- Checking directly from the blockchain (so can buy from presale)
          const vipWhitelisted = await contract.methods.whitelist(this.state.account).call().catch(error => {

          });

          this.setState({ vipWhitelisted })
          //console.log("getting info TBHIS ONE MISSSINF... inside the VIPWHITELISTING", this.state.vipWhitelisted )


        } catch (e) {
          console.log('Error from this loooooog', e)
          window.alert('ERRORs -- Generic error in the web3')
        }

      } else {
        window.alert('Please install MetaMask')
      }
    }   // END OF LOADING block chain data


    async componentDidMount() {

        const queryString = window.location.search;
        //console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        const successParam = urlParams.has('success')
        if (successParam) {
            window.history.pushState(null, null, window.location.pathname);
            this.props.addToast('Thanks for Getting VIP Access - Please wait for blockchain confirmation.', { appearance: 'success' });
        }

        await this.loadWeb3(this.props.dispatch)

    }

    async componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
//        if (this.props.vipAccessPrice !== prevProps.vipAccessPrice) {
//            this.setState({ vipAccessPrice: this.props.vipAccessPrice })
//        }



    }




    render() {

        return (
            <React.Fragment>

                <section className="what-we-do-area bg-black pt-100 pb-100">

                    <div className="container ">
                        {true ?
                            <div className="row justify-content-center">
                                <div className="col-10">

                                    { (this.props.vipwhitelistingon != true ) ?


                                        <div className="feature feature-1 service-1 text-center">
                                            <hr />
                                            <h4> VIP whitelist not open yet.</h4>
                                            <div>We are just making sure all works as intended</div>
                                            <hr />
                                        </div>

                                        :

                                        <div className="feature feature-1 service-1 text-center">


                                            <h5>YOUR CURRENT STATUS FOR THIS ADDRESS : {this.state.vipWhitelisted== false ? "Not whitelisted yet" : "whitelisted already"} </h5>

                                            <h6>Ticket Price : {this.state.vipAccessPrice / (10 ** 18)} ETH Only </h6>
                                            <hr />


                                            <form onSubmit={(e) => {
                                                e.preventDefault()    //doesnt let the page refresh
                                                this.weVIPWhitelist(e)


                                            }}>
                                                <div className="form-row justify-content-center">
                                                    <div className="form-group col-4 col-md-5 ">
                                                        <input
                                                            type='hidden'
                                                            placeholder='ETH address'
                                                            className="form-control mb-3"

                                                            />

                                                        <input type='submit' className='btn btn-block default-btn-custom' value='Get VIP Access to WhiteList' />
                                                    </div>
                                                </div>
                                            </form>







                                            <hr />

                                        </div>

                                    }
                                </div>
                            </div>
                            :

                            <VipwhitelistingLoading />}
                    </div>


                </section>

            </React.Fragment>
        )
    }
}
// Specifies the default values for props:
Vipwhitelisting.defaultProps = {
    vipAccessPrice: 10
};


export default WithToast(Vipwhitelisting);
