import React from 'react';

const ContactUS = () => {
    return (
        <section className="what-we-do-area bg-black pt-100 pb-70">

            <div className="container ">
                <div className="row">

                    <div className="col-md-10 col-md-push-1 col-sm-10 col-sm-push-1">

                        <div className="feature feature-1 service-1 text-center">

                            <h3 align="left">Contact US</h3>
                            <hr />
                            <hr />
                        </div>

                    </div>

                </div>
            </div>


        </section>
    )
}

export default ContactUS;