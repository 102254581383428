import React, { useState } from 'react';
import ReactDOM from "react-dom";
import PropTypes from "prop-types"
import '../../stylesheets/custom'
import '../../stylesheets/custom.css'


const Solutions6 = (props) => {

    const [onilne, setOnline] = useState(false);


    return (
        <React.Fragment>
            <section className="projects-area bg-color-black pt-5 pb-5 ">
                <div className="container bg-color-black  border-line-bottom">
                    <div className="row justify-content-center mt-1 ">

                        <React.Fragment>
                            <div className="col-12 col-md-12 col-lg-12 pb-0 ">
                                <div className="d-flex flex-column justify-content-center" >
                                    <h2 className="rockwell-font" style={{ color: '#ffffff', fontSize: '52px' }}>
                                    <span style={{ color: '#F2356D' }}> DISCORD</span> /  <span style={{ color: '#F2356D' }}> TWITTER</span>
                                    </h2>

                                </div>

                            </div>


                            <div className="col-4  pt-3 ">
                                <a href="https://discord.gg/Myn8Uw29Eh" target="_blank" className="d-flex flex-row align-items-center  p-4 lg:p-5 m-1 lg:m-5 bg-blurple hover:bg-black rounded-lg cursor-pointer">
                                    <i className='d-flex  justify-content-center bx bxl-discord bx-normal-white-custom' style={{width:"100px"}}></i>
                                    <p className="text-white font-extrabold lg:text-2xl self-center">Join Official Discord!</p>
                                </a>

                            </div>


                            <div className="col-4  pt-3 ">

                                <a href="https://twitter.com/@niftycashart" target="_blank" className="d-flex flex-row align-items-center  p-4 lg:p-5 m-1 lg:m-5 bg-tweet hover:bg-black rounded-lg cursor-pointer">
                                    <i className='d-flex  justify-content-center bx bxl-twitter bx-normal-white-custom' style={{width:"100px"}}></i>
                                    <p className="text-white font-extrabold lg:text-2xl self-center">Follow us on Twitter!</p>
                                </a>
                               
                            </div>



                        </React.Fragment>

                    </div>

                </div>
            </section>

        </React.Fragment>
    )
}

Solutions6.propTypes = {
    artWorksData: PropTypes.array,
    S3_ADDRESS: PropTypes.string
};

export default Solutions6;
