import React from 'react';
//import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
//import FooterMin from './_App/FooterMin';
//import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
//import Team from './Common/Team';
import Fidenza from './Common/Fidenza';
import {  RecoilRoot } from 'recoil'



class P5testPage2 extends React.Component {
  render() {
    return (
      <React.Fragment>
        <RecoilRoot>
          <Fidenza />
        </RecoilRoot>
      </React.Fragment>
    );
  }
}

export default P5testPage2
