import React from 'react';

const Percent20 = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={props.width} height={props.height} viewBox="0 0 981.000000 982.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,982.000000) scale(0.100000,-0.100000)"
        fill={props.color} stroke="none">
        <path d="M4450 9799 c-570 -55 -1180 -227 -1695 -479 -479 -235 -880 -520
-1277 -909 -772 -755 -1235 -1656 -1423 -2768 -59 -348 -61 -1094 -5 -1438
170 -1041 602 -1936 1281 -2650 483 -508 990 -869 1599 -1138 477 -210 1095
-368 1563 -398 231 -15 873 -6 1021 15 1113 156 2099 639 2861 1401 737 738
1208 1666 1390 2742 43 253 58 751 35 1144 -30 523 -220 1212 -480 1744 -249
507 -533 901 -954 1320 -500 498 -1026 844 -1656 1090 -360 140 -804 256
-1145 299 -252 32 -893 46 -1115 25z m850 -619 c427 -34 853 -139 1273 -314
884 -367 1636 -1049 2112 -1915 255 -463 450 -1088 496 -1586 15 -167 15 -731
0 -900 -37 -404 -161 -868 -346 -1290 -399 -916 -1157 -1698 -2070 -2135 -444
-213 -960 -360 -1408 -402 -174 -16 -746 -15 -912 1 -537 52 -1175 261 -1665
546 -362 210 -654 438 -955 746 -522 534 -883 1179 -1075 1924 -82 317 -106
476 -120 804 -12 272 0 665 26 840 130 879 488 1638 1083 2296 713 788 1679
1275 2771 1395 100 11 611 4 790 -10z"/>
        <path d="M2711 6429 c-234 -39 -441 -232 -498 -467 -22 -90 -21 -260 2 -395
16 -93 21 -108 39 -112 12 -2 90 -6 174 -7 l152 -3 5 30 c2 17 7 82 10 145 13
229 45 340 121 414 56 55 98 71 185 71 67 0 81 -4 125 -30 99 -62 130 -162
113 -365 -10 -116 -41 -227 -111 -391 -62 -147 -310 -640 -617 -1228 l-221
-423 0 -147 0 -146 697 3 c384 2 701 6 704 10 4 4 9 84 12 179 l5 172 -433 3
c-238 2 -437 7 -442 11 -4 5 20 63 55 130 117 230 334 642 505 962 258 484
301 584 328 764 14 101 7 298 -15 388 -51 205 -196 354 -392 403 -149 37 -376
50 -503 29z"/>
        <path d="M4530 6430 c-205 -33 -373 -156 -464 -341 -113 -228 -124 -381 -108
-1389 6 -383 16 -721 22 -753 15 -83 70 -215 125 -297 86 -130 221 -226 375
-267 92 -25 339 -24 430 1 280 77 470 317 510 646 15 123 24 1267 12 1566 -10
250 -10 250 -47 362 -47 140 -93 219 -175 303 -115 116 -258 167 -490 174 -74
2 -160 0 -190 -5z m245 -345 c77 -20 117 -52 150 -119 24 -48 27 -70 35 -227
11 -210 12 -1105 1 -1535 l-8 -311 -29 -53 c-16 -29 -44 -64 -63 -79 -30 -23
-45 -26 -135 -29 -128 -5 -174 7 -219 57 -47 52 -56 88 -67 261 -12 183 -12
1562 0 1733 7 108 13 137 35 183 30 61 74 100 137 119 57 18 92 18 163 0z"/>
        <path d="M6174 6406 c-120 -28 -213 -96 -264 -196 -76 -150 -100 -540 -45
-755 52 -203 180 -320 365 -332 197 -14 321 52 408 215 54 101 62 152 62 398
0 321 -19 428 -96 528 -90 118 -271 178 -430 142z m182 -235 c19 -12 36 -33
42 -53 15 -47 23 -386 14 -560 -6 -126 -11 -151 -28 -175 -46 -61 -136 -67
-198 -13 -55 49 -61 89 -61 395 0 230 3 278 18 328 14 45 25 62 50 77 42 26
121 26 163 1z"/>
        <path d="M7086 6339 c-18 -62 -590 -2220 -600 -2262 l-7 -28 123 3 123 3 237
890 c130 490 269 1009 308 1154 43 160 67 268 62 273 -5 5 -59 12 -120 15
l-111 6 -15 -54z"/>
        <path d="M7383 5306 c-134 -44 -220 -158 -260 -346 -28 -130 -24 -474 5 -584
45 -164 113 -261 221 -313 52 -26 65 -28 176 -28 154 1 213 21 296 104 96 97
131 200 148 442 28 383 -57 626 -248 712 -59 27 -271 35 -338 13z m238 -227
c59 -46 62 -68 63 -394 1 -323 -4 -357 -59 -412 -25 -25 -41 -33 -70 -33 -43
0 -65 10 -123 58 l-43 35 3 326 c3 316 4 327 26 373 21 43 40 58 102 81 25 10
62 -3 101 -34z"/>
      </g>
    </svg>

  );
}
export default Percent20;