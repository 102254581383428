import React from 'react';
import { useToasts } from 'react-toast-notifications';

import Web3 from 'web3';
import WithToast from '../Common/WithToast.js'


const TransactionLoading = (props) => {
    return (

        <div className="row justify-content-center">
            <div className="col-10  pt-5">
                <div className="d-flex flex-row text-center  justify-content-center">
                <div><p style={{color:"white"}}>Please connect with Metamask</p></div>
                </div>
            </div>
        </div>
    )
}

class Transactions extends React.Component {

    async loadWeb3(dispatch) {
        if (window.ethereum) {

          const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
          const netId = await web3.eth.net.getId()     // gets the value of the network
          const enabledWeb3 = await ethereum.enable();
          const accounts = await web3.eth.getAccounts()

          //load balance
          if (typeof accounts[0] === 'undefined') {
            this.props.addToast('Please connect the metamask', { appearance: 'info' });
          }

        } else {
          this.props.addToast('Please install MetaMask', { appearance: 'info' });
        }
      }   // END OF LOADING block chain data

    // Minting function
    async wemint(tknumber, totalpayamount, event) {

        if (window.ethereum) {
            const web3 = new Web3(window.ethereum)
            const accounts = await web3.eth.getAccounts()
            //load balance
            if (typeof accounts[0] === 'undefined') {
                event.target.reset();
                this.props.addToast('Sorry, please connect the metamask', { appearance: 'error' });
                return
            } else {

                this.props.updateAccount(accounts[0])

            }
        }

        //console.log("this.props.currentPrice --> ", this.props.currentPrice)
        //console.log("this.props.account --> ", this.props.account)
        //console.log("this.props.contract --> ", this.props.contract)

        //PreSaleMintFromWhiteList

        if (this.props.contract !== 'undefined') {
            try {

                await this.props.contract.methods.regularMint(tknumber).send({ from: this.props.account, value: totalpayamount })
                    .on('transactionHash', function (hash) {
                        //console.log("transactionHash State")

                        window.location.search = '?success';
                    })
                    .once('receipt', (receipt) => {
                        this.setState({
                            action: "buy"
                        })
                        event.target.reset();
                    })


            } catch (e) {
                event.target.reset();
                this.props.addToast('Sorry, there has been an error', { appearance: 'error' });
                console.log('Error, withdraw: ', e)
            }
        }
    }



    constructor(props) {
        super(props);

        this.state = {
            isToggleOn: true,
            currentPrice: this.props.currentPrice,
            action: "wait"

        };

        // This binding is necessary to make `this` work in the callback    this.handleClick = this.handleClick.bind(this);
    }


    async componentDidMount() {

        const queryString = window.location.search;
        //console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        const successParam = urlParams.has('success')
        if (successParam) {
            window.history.pushState(null, null, window.location.pathname);
            this.props.addToast('Thanks for your purchase - Please wait for Blockchain confirmation', { appearance: 'success' });
        }


        if (this.props.currentPrice == 100) {
            this.setState({ currentPrice: 1000 })
            this.props.addToast('Sale has already ended!', { appearance: 'success' });
        }

        await this.loadWeb3(this.props.dispatch)

    }

    async componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.currentPrice !== prevProps.currentPrice) {
            this.setState({ currentPrice: this.props.currentPrice })
        }



    }

    render() {

        return (
            <React.Fragment>

                <section className="what-we-do-area bg-black pt-100 pb-100">

                    <div className="container ">
                        {this.props.currentPrice != 10 ?
                            <div className="row justify-content-center">
                                <div className="col-10">

                                    {((this.state.currentPrice == Web3.utils.toWei('1000', 'ether')) || (this.props.salesison != true)) ?


                                        <div className="feature feature-1 service-1 text-center">
                                            <hr />
                                            <h4> Sale not LIVE yet </h4>
                                            <div>Please check Discord for more information.</div>
                                            <hr />
                                        </div>


                                        :

                                        <div className="feature feature-1 service-1 text-center">

                                            <h4>Price Now : {this.state.currentPrice / (10 ** 18)} ETH  </h4><hr />

                                            <p align="centers"> Quantity to Buy (max {this.props.max_NFT_ATONCE_FULLSALE} at once)</p>



                                            <form onSubmit={(e) => {
                                                e.preventDefault()    //doesnt let the page refresh

                                                const tknumber = this.msize.value
                                                const totalPrice = tknumber * this.state.currentPrice;
                                                console.log('totalPrice: ', totalPrice / (10 ** 18), "ETH")
                                                //    this.wemint(tknumber,totalPrice.toString())


                                                this.wemint(tknumber, totalPrice.toString(), e)


                                            }}>
                                                <div className="form-row justify-content-center">
                                                    <div className="form-group col-4 ">
                                                        <input
                                                            type='number'
                                                            className="form-control mb-3"
                                                            placeholder={'e.g. 1-'+this.props.max_NFT_ATONCE_FULLSALE}
                                                            ref={(input) => { this.msize = input }}
                                                            min="1"
                                                            max={this.props.max_NFT_ATONCE_FULLSALE}
                                                            required />

                                                        <input type='submit' className='btn btn-block default-btn-custom' value='PURCHASE' />
                                                    </div>
                                                </div>
                                            </form>


                                            <hr />

                                        </div>

                                    }
                                </div>
                            </div>
                            :

                            <TransactionLoading />}
                    </div>


                </section>

            </React.Fragment>
        )
    }
}
// Specifies the default values for props:
Transactions.defaultProps = {
    currentPrice: 10
};


export default WithToast(Transactions);
