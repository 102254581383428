import React, { useState } from 'react';
import ReactDOM from "react-dom";
import PropTypes from "prop-types"
import CountDown from './CountDown';
import '../../stylesheets/custom'
import '../../stylesheets/custom.css'
import { container } from 'webpack';
import testMP4 from './../../../videos/costa.mp4'

// <section className="projects-area pt-5 pb-5 " style={{backgroundImage: 'url('+bg_cyberwood+')', width:"100vh", height: "auto", backgroundSize:"contain",backgroundRepeat:"no-repeat", backgroundPosition: "center"  }}>
//<section className="background-main-size pt-5 pb-5 cinema-bg" style={{ backgroundImage: 'url(' + bg_cyberwood + ')' }}> 
//<video  width="770" height="325" className=" z-depth-1 center-block animate__fadeIn cinema-video" style={{ animationDuration: '5s'}} autoPlay loop controls muted >
//<source src={testMP4} type="video/mp4" />
//</video>


const Solutions1 = (props) => {

    const [onilne, setOnline] = useState(false);
    var bg_cyberwood = props.S3_ADDRESS + "website/bg.jpg"

    return (
        <React.Fragment>
            <section className="background-main-size pt-5 pb-5 " style={{ backgroundImage: 'url(' + bg_cyberwood + ')', width: "100vw", maxWidth: "100%", backgroundSize: "cover" }}>
                <div>
                </div>
            </section>
        </React.Fragment>
    )
}

Solutions1.propTypes = {
    artWorksData: PropTypes.array,
    S3_ADDRESS: PropTypes.string
};

export default Solutions1;
