import React from 'react';

const Percent60 = (props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={props.width} height={props.height} viewBox="0 0 981.000000 982.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,982.000000) scale(0.100000,-0.100000)"
        fill={props.color} stroke="none">
        <path d="M4450 9799 c-570 -55 -1180 -227 -1695 -479 -479 -235 -880 -520
-1277 -909 -772 -755 -1235 -1656 -1423 -2768 -59 -348 -61 -1094 -5 -1438
170 -1041 602 -1936 1281 -2650 483 -508 990 -869 1599 -1138 477 -210 1095
-368 1563 -398 231 -15 873 -6 1021 15 1113 156 2099 639 2861 1401 737 738
1208 1666 1390 2742 43 253 58 751 35 1144 -30 523 -220 1212 -480 1744 -249
507 -533 901 -954 1320 -500 498 -1026 844 -1656 1090 -360 140 -804 256
-1145 299 -252 32 -893 46 -1115 25z m850 -619 c427 -34 853 -139 1273 -314
884 -367 1636 -1049 2112 -1915 255 -463 450 -1088 496 -1586 15 -167 15 -731
0 -900 -37 -404 -161 -868 -346 -1290 -399 -916 -1157 -1698 -2070 -2135 -444
-213 -960 -360 -1408 -402 -174 -16 -746 -15 -912 1 -537 52 -1175 261 -1665
546 -362 210 -654 438 -955 746 -522 534 -883 1179 -1075 1924 -82 317 -106
476 -120 804 -12 272 0 665 26 840 130 879 488 1638 1083 2296 713 788 1679
1275 2771 1395 100 11 611 4 790 -10z"/>
        <path d="M2670 6430 c-135 -16 -245 -73 -355 -184 -66 -66 -90 -99 -127 -176
-105 -212 -112 -304 -105 -1310 5 -782 6 -784 67 -952 86 -237 234 -372 465
-425 111 -25 372 -25 464 0 189 52 344 188 416 365 54 134 60 193 60 627 0
350 -2 408 -18 464 -28 102 -84 198 -162 277 -111 113 -215 154 -392 154 -145
0 -272 -39 -381 -117 l-47 -33 -3 127 c-4 177 15 630 28 675 34 117 125 184
265 194 213 16 281 -78 301 -413 l7 -123 146 0 c80 0 156 3 169 6 43 12 56 56
60 216 3 88 -1 175 -8 215 -28 163 -155 301 -347 378 -97 38 -333 55 -503 35z
m309 -1538 c83 -41 99 -79 112 -267 13 -175 6 -703 -10 -756 -39 -131 -227
-203 -366 -140 -95 42 -129 94 -144 222 -13 114 -25 668 -16 763 8 90 26 117
107 161 109 60 220 66 317 17z"/>
        <path d="M4515 6430 c-138 -25 -275 -98 -361 -193 -63 -68 -148 -231 -169
-321 -27 -115 -35 -342 -35 -1001 0 -901 9 -997 101 -1184 84 -169 221 -289
392 -343 54 -17 86 -19 257 -16 173 3 201 6 251 26 187 75 326 215 398 401 50
131 60 206 72 549 12 345 6 1199 -11 1392 -30 361 -199 598 -477 671 -89 23
-333 34 -418 19z m290 -359 c119 -52 132 -91 146 -416 13 -283 5 -1678 -9
-1743 -16 -70 -61 -133 -117 -161 -43 -22 -62 -26 -140 -26 -73 1 -98 5 -132
23 -54 28 -100 85 -113 141 -16 68 -34 1385 -23 1716 l9 270 31 65 c44 93 90
136 161 151 51 10 139 1 187 -20z"/>
        <path d="M6157 6401 c-136 -31 -250 -137 -293 -274 -21 -65 -32 -520 -16 -635
20 -141 107 -278 213 -337 52 -29 58 -30 184 -30 99 0 142 5 183 18 126 44
233 200 252 367 13 109 13 482 1 546 -23 122 -85 235 -155 283 -94 65 -246 90
-369 62z m182 -226 c61 -31 81 -134 82 -412 0 -215 -10 -303 -42 -366 -44 -85
-181 -90 -224 -8 -38 74 -62 341 -45 516 17 186 40 253 95 276 33 13 104 10
134 -6z"/>
        <path d="M7085 6378 c-2 -7 -85 -319 -184 -693 -99 -374 -236 -891 -305 -1149
-69 -258 -123 -473 -120 -477 5 -9 234 -13 234 -4 0 2 47 177 104 387 90 328
354 1319 470 1759 20 75 36 145 36 156 0 17 -9 21 -61 27 -99 10 -169 7 -174
-6z"/>
        <path d="M7450 5324 c-144 -28 -227 -90 -291 -218 -37 -75 -42 -94 -51 -197
-14 -163 -5 -465 17 -559 34 -149 110 -247 224 -291 47 -19 76 -23 186 -23
118 -1 134 1 175 23 106 57 194 185 226 331 22 98 25 470 5 578 -28 155 -126
286 -249 332 -56 22 -187 34 -242 24z m139 -227 c32 -16 60 -55 73 -103 15
-59 22 -477 9 -573 -13 -101 -31 -135 -81 -161 -82 -42 -153 -10 -191 87 -43
114 -37 583 10 689 16 35 30 49 63 64 44 20 71 19 117 -3z"/>
      </g>
    </svg>

  );
}
export default Percent60;