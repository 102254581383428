import React from "react"
import PropTypes from "prop-types"

//imported specially for this page
import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
import FooterMin from './_App/FooterMin';
import { RecoilRoot } from 'recoil'
import GetNFTs from './Common/GetNFTs';



class GetNFTsPage extends React.Component {


  constructor(props) {
    super(props);
  }

  render() {
    return (

      <React.Fragment>
        <RecoilRoot>
   

          <GetNFTs S3_ADDRESS = {this.props.S3_ADDRESS} />
          <FooterMin  />
          <GoTop />
        </RecoilRoot>

      </React.Fragment>
    );
  }
}

GetNFTsPage.propTypes = {
  s3Address: PropTypes.string
};
export default GetNFTsPage
