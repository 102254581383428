import React from 'react';
import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
import FooterMin from './_App/FooterMin';
import Footer from './_App/Footer';
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
import Team from './Common/Team';
import {  RecoilRoot } from 'recoil'

//          <FooterMin fixedBottom={false} />
class TeamPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <RecoilRoot>

          <Team  S3_ADDRESS={this.props.S3_ADDRESS} />
          <Footer S3_ADDRESS={this.props.S3_ADDRESS} user_signed_in={this.props.user_signed_in} />
          <GoTop />
        </RecoilRoot>
      </React.Fragment>
    );
  }
}

export default TeamPage
