import React, { useState } from 'react';
import ReactDOM from "react-dom";
import PropTypes from "prop-types"
import CountDown from './CountDown';
import '../../stylesheets/custom'
import '../../stylesheets/custom.css'

// import Percent20 from "./Percent20"
// import Percent40 from "./Percent40"
// import Percent60 from "./Percent60"
//import Percent80 from "./Percent80"
// import Percent100 from "./Percent100"

import Percent25 from "./Percent25"
import Percent50 from "./Percent50"
import Percent75 from "./Percent75"
import Percent100 from "./Percent100_2"

import testMP4 from './../../../videos/test.mp4'


const Solutions3 = (props) => {

    const [onilne, setOnline] = useState(false);


    return (
        <React.Fragment>
            <section className="projects-area   pt-5 pb-5 ">
                <div className="container ">
                    <div className="row justify-content-lg-between justify-content-md-center mt-1 ">
                        {props.salesIsOn ?
                            <div className="col-11 pb-5 ">
                                <div className="d-flex justify-content-center">


                                    <a href="/transactions" className="px-5 py-5" >
                                        <img src={props.S3_ADDRESS + "webimages/buy_now_resize.png"} className="banner-img " alt="distribution" />
                                    </a>
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                <div className="col-10 col-md-10 col-lg-6 pb-3  ">
                                    <div className="d-flex flex-column justify-content-center" >
                                        <h2 className="rockwell-font pb-3 color-FFFFFF  " style={{  fontSize: '52px' }}>
                                            THE
                                            <span style={{ color: '#F2356D' }}> TECH SCRIPT</span>
                                        </h2>

                                        <ul className="ul-custom pl-0 pb-3" style={{ listStyleType: "none" }}>
                                            <li className="d-flex align-items-center">
                                                <div className=" rounded-full mr-1"></div>
                                                <span style={{ fontWeight: "700", fontSize: "20px", color: '#FFFFFF' }}>ERC-721 Contract</span>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <div className="rounded-full  mr-1"></div>
                                                <span style={{ fontWeight: "700", fontSize: "20px", color: '#FFFFFF' }}>ETH Blockchain</span>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <div className=" rounded-full  mr-1"></div>
                                                <span style={{ fontWeight: "700", fontSize: "20px", color: '#FFFFFF' }}>Rarities Set</span>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <div className=" rounded-full  mr-1"></div>
                                                <span style={{ fontWeight: "700", fontSize: "20px", color: '#FFFFFF' }}>Anti-Bot Tech</span>
                                            </li>


                                        </ul>


                                        <div className="d-flex flex-row  mb-3">
                                            <div className="d-flex col-6 p-0 m-0 flex-column rockwell-font">
                                                <div className="d-flex flex-column rockwell-font">
                                                    <div className="rockwell-font ">
                                                        <p style={{ color: '#FFFFFF', fontSize: '30px', fontWeight: '500' }}>
                                                            6666 NFTs
                                                    </p>
                                                    </div>
                                                    <div className="rockwell-font ">
                                                        <p style={{ color: '#71717A', fontSize: '16px', fontWeight: '500' }}>
                                                            Crafted with care including rarities and animated by cutting edge tech
                                                    </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column rockwell-font ">
                                                    <div className="rockwell-font ">
                                                        <p style={{ color: '#FFFFFF', fontSize: '30px', fontWeight: '500' }}>
                                                            Rarity Listing
                                                    </p>
                                                    </div>
                                                    <div className="rockwell-font ">
                                                        <p style={{ color: '#71717A', fontSize: '16px', fontWeight: '500' }}>
                                                            Collection will be listed on rarity.tools so you can snipe the good ones.
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex col-6 p-0 m-0 flex-column rockwell-font">
                                                <div className="d-flex flex-column rockwell-font">
                                                    <div className="rockwell-font ">
                                                        <p style={{ color: '#FFFFFF', fontSize: '30px', fontWeight: '500' }}>
                                                            PRICE 0.077 ETH
                                                    </p>
                                                    </div>
                                                    <div className="rockwell-font ">
                                                        <p style={{ color: '#71717A', fontSize: '16px', fontWeight: '500' }}>
                                                            Animated is the next-gen style of NFTs. Be the first to get involved.
                                                    </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column rockwell-font ">
                                                    <div className="rockwell-font ">
                                                        <p style={{ color: '#FFFFFF', fontSize: '30px', fontWeight: '500' }}>
                                                            Pre-sale
                                                    </p>
                                                    </div>
                                                    <div className="rockwell-font ">
                                                        <p style={{ color: '#71717A', fontSize: '16px', fontWeight: '500' }}>
                                                            VIP access to the pre-sale for early joiners for a stress free minting experience. See more in Discord.
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                </div>
                                <div className="col-4 col-lg-4 px-0 d-flex justify-content-center align-items-end">
                                    <img src="https://mclub88.s3.ap-southeast-1.amazonaws.com/website/illus1.jpg" className="align-self-center" style={{ width: "400px" }} />

                                </div>



                            </React.Fragment>

                        }

                    </div>

                </div>
            </section>

        </React.Fragment>
    )
}

Solutions3.propTypes = {
    artWorksData: PropTypes.array,
    S3_ADDRESS: PropTypes.string
};

export default Solutions3;
