import React from 'react';
import PageBanner from '../components/Common/PageBanner';
import Lightbox from 'react-image-lightbox';


const images_default = [
    (require('gallery/gallery1.jpg')),
    (require('gallery/gallery2.jpg')),
    (require('gallery/gallery3.jpg')),
    (require('gallery/gallery1.jpg')),
    (require('gallery/gallery2.jpg')),
    (require('gallery/gallery3.jpg')),
    (require('gallery/gallery1.jpg')),
    (require('gallery/gallery2.jpg')),
    (require('gallery/gallery3.jpg')),
];

const images = [
    (require('gallery2/gallery1.png')),
    (require('gallery2/gallery2.png')),
    (require('gallery2/gallery1.png')),
    (require('gallery2/gallery2.png')),
    (require('gallery2/gallery1.png')),
    (require('gallery2/gallery2.png')),
    (require('gallery2/gallery1.png')),
    (require('gallery2/gallery2.png')),
    (require('gallery2/gallery1.png')),
];

const Gallery = () => {
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [isOpenImage, setIsOpenImage] = React.useState(false);

    return (
        <React.Fragment>
            <PageBanner
                pageTitle="Gallery" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Gallery" 
            />

            <section className="gallery-area bg-color-black pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                    <a href="#popup"
                                        onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(0);}}
                                    >
                                        <img src={images[0]} alt="image" />
                                    </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                              
                                    <a  href="#popup"
                                        onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(1);}}
                                    >
                                        <img src={images[1]} alt="image" />
                                    </a>
                             
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                               
                                    <a  href="#popup" 
                                        onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(2);}}
                                    >
                                        <img src={images[2]} alt="image" />
                                    </a>
                              
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                               
                                    <a  href="#popup"
                                        onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(3);}}
                                    >
                                        <img src={images[3]} alt="image" />
                                    </a>
                        
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                
                                    <a  href="#popup"
                                        onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(4);}}
                                    >
                                        <img src={images[4]} alt="image" />
                                    </a>
                              
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                              
                                    <a  href="#popup"
                                        onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(5);}}
                                    >
                                        <img src={images[5]} alt="image" />
                                    </a>
                              
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                
                                    <a  href="#popup"
                                        onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(6);}}
                                    >
                                        <img src={images[6]} alt="image" />
                                    </a>
                           
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                            
                                    <a   href="#popup"
                                        onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(7);}}
                                    >
                                        <img src={images[7]} alt="image" />
                                    </a>
                              
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                             
                                    <a  href="#popup"
                                        onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(8);}}
                                    >
                                        <img src={images[8]} alt="image" />
                                    </a>
                               
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                              
                                    <a  href="#popup"
                                        onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(8);}}
                                    >
                                        <img src={images[8]} alt="image" />
                                    </a>
                               
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                               
                                    <a   href="#popup"
                                        onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(8);}}
                                    >
                                        <img src={images[8]} alt="image" />
                                    </a>
                              
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                
                                    <a  href="#popup"
                                        onClick={e => {e.preventDefault(); setIsOpenImage(true); setPhotoIndex(8);}}
                                    >
                                        <img src={images[8]} alt="image" />
                                    </a>
                             
                            </div>
                        </div>
                    </div>
                </div>

                {/* Lightbox */}
                {isOpenImage && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpenImage(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </section>
            
        </React.Fragment>
    );
}

export default Gallery;