import React, { useEffect, useRef } from "react"
//import ReactDom from 'react-dom'
import PropTypes from "prop-types"
import PageBanner from './Common/PageBanner';
import Lightbox from 'react-image-lightbox';
import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom /> //
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />
import FooterMin from './_App/FooterMin';
import { RecoilRoot } from 'recoil'
import axios from "axios";
import snakePeakBG from './../../images/snakepeakbg.jpg'
import cityframe from './../../images/cityframe.jpg'


// moviesData.map((artWork, index) =>
// <div className="d-flex flex-row justify-content-center" key={index} style={{  marginBottom: "0px", backgroundImage: "url("+props.S3_ADDRESS +"website/cityframev2.jpg)" , backgroundSize: "cover", backgroundRepeat:"no-repeat", backgroundPosition:"center", minHeight: "inherit"}}>

//         <div className="d-flex flex-column justify-content-center pb-5" >
//             <video width="290" hight="600" src={props.S3_ADDRESS + "movies/" + artWork.internalname} className="z-depth-1 animate__fadeIn video-custom" style={{ minHeight:"500px",height: "auto", animationDuration: '5s', objectFit: "contain" }} autoPlay loop controls muted key={index} >
//             </video>
//             <div className="d-flex justify-content-center align-items-center pt-5">
//                 <button type="button" onClick={getTodoItems} className="default-btn-custom">Load more</button>
//             </div>
//         </div>
//         <div>
//         </div>
// </div>
// )


const ArtWorks = (props) => {
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [maxArtWorks, setMaxArtWorks] = React.useState(props.maxArtworks);
    const [moviesCount, setArtWorksCount,] = React.useState(1);
    const [moviesBatchSize, setArtWorksBatchSize,] = React.useState(1);
    const [moviesData, setArtWorksData] = React.useState();
    const [videoIndex,setVideoIndex] = React.useState(0);


    const getArtWorkItems = async (batchsize) => {
        try {
            const response = await axios.get("/sneakpeak", { params: { batchsize: batchsize } });
            return response.data;
        } catch (err) {
            console.error(err);
        }
    }

    const getNext = ()=>{

        if(moviesData.length==(videoIndex+1)){
            setVideoIndex(0)
        }
        else{
            setVideoIndex(videoIndex+1)
        }
        //console.log(videoIndex)

    }

    const getUniqueItems = (todoItems) => {

        //const newStateData = moviesData.concat(todoItems);

        let tempDic = {};
        todoItems.map((data) => {
            tempDic[data.id] = data.internalname
        });

        let result = [];

        for (const [key, value] of Object.entries(tempDic)) {
            //console.log(key+"  : "+value)
            //result.push({"id":key, "internalname":value})

            if (!(moviesData.find(obj => {
                //console.log("check id :"+obj.id)
                return parseInt(obj.id) == parseInt(key)
            }))) {
                result.push({ "id": parseInt(key), "internalname": value })
            }

        }

        return result;
    }

    const getTodoItems_bak = async () => {
        if (moviesData.length < maxArtWorks) {
            //setArtWorkFocus(moviesData[moviesData.length-1].id);

            const todoItems = await getArtWorkItems(moviesBatchSize);

            let result = moviesData.concat(getUniqueItems(todoItems));

            const targetSize = moviesFirstLength + (moviesCount * moviesBatchSize);

            while ((result.length < targetSize) && (result.length < maxArtWorks)) {
                console.log("Get More!!");
                let leftItems = targetSize - result.length
                result = result.concat(getUniqueItems(await getArtWorkItems((leftItems < moviesBatchSize) ? leftItems : moviesBatchSize)));
            }

            setArtWorksData([...result]);
            setArtWorksCount(moviesCount + 1);

        }
    }

    const getTodoItems = async () => {
        // setArtWorkFocus(moviesData[moviesData.length-1].id);
        const todoItems = await getArtWorkItems(moviesBatchSize);
        setArtWorksData(getUniqueItems(todoItems));
    }

    const getBase64img = async (urlImg) =>{


        var img = new Image();
        img.src = urlImg;
        console.log(img)

        var reader = new FileReader();

        reader.readAsDataURL(urlImg)
        //console.log(reader)
    }

    useEffect(() => {
        // Create an scoped async function in the hook
        //bg-color-dark-blue
    //     <div className="d-flex justify-content-center align-items-center">
    //     <button type="button" onClick={getTodoItems} className="default-btn-custom">Load more</button>
    // </div>
        async function getArtWorkItemsWrap(moviesBatchSize) {
            const todoItems = await getArtWorkItems(moviesBatchSize);
            //console.log(todoItems)
            setArtWorksData(todoItems);
        }

        getArtWorkItemsWrap(300)
      }, []);
    
    return (

        <React.Fragment>
            <RecoilRoot>
                <GoTop />

                <section className=" pt-4 pb-3 px-0 mx-0 bg-black" >

                    <div className="container" style={{ minHeight: "825px" }}>
                        
                        {


                        typeof moviesData === 'undefined' ? 
                            
                        <div style={{ height: "825px"}} ></div>

                        :
                        
                        <div className="row">
                            <div className="col">
                                <div className="d-flex flex-row justify-content-around"style={{  marginBottom: "0px", backgroundColor:"black" , backgroundSize: "cover", backgroundRepeat:"no-repeat", backgroundPosition:"center", minHeight: "inherit"}}>
                                    <div className="d-flex justify-content-center align-items-center pt-5 pr-5">
                                                <button type="button" onClick={getNext} className="rounded-btn">LOAD AGAIN</button>
                                        </div>

                                        <div className="d-flex flex-row d-flex justify-content-center align-items-center" >
            

                                            <img src={props.S3_ADDRESS + "pictures/" + moviesData[videoIndex].internalname} alt="image" className=" rounded" style={{ width:"auto", height:"700px"}} />


                                        </div>

                                        <div className="d-flex flex-column justify-content-center align-items-center  pl-5 ml-5 ml-md-0  pt-5">
                                            <p className="rockwell-font text-center" style={{ color: '#ffffff', fontSize: '26px', fontWeight: '500' }} > Want Some? Join DISCORD Now</p>
                                            <a href="https://discord.gg/Myn8Uw29Eh" className="d-block" style={{ width: "80px" }} target="_blank">
                                                    <i className='d-flex justify-content-center bx bxl-discord bx-normal-custom'></i>

                                            </a>
                                        </div>
                                </div>
                            </div>
                        </div>

                        }
                    </div>


                </section>

                <FooterMin fixedBottom={false} />
            </RecoilRoot>
        </React.Fragment>
    );
}


ArtWorks.propTypes = {
    moviesData: PropTypes.array,
    moviesIDs: PropTypes.array,
    maxArtworks: PropTypes.number,
    S3_ADDRESS: PropTypes.string
};

export default ArtWorks
